.LiveClass {
	display: flex;
	flex-direction: column;

	.Heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 46px;

		.Left {
			h1 {
				font-weight: 400;
				font-size: 32px;
				line-height: 21px;
				letter-spacing: -0.006em;
				color: #ffffff;

				.icon {
					cursor: pointer;
				}
			}
			p {
				font-weight: 400;
				font-size: 18px;
				line-height: 21px;
				letter-spacing: -0.006em;
				color: #666666;
				margin: 0;
				margin-left: 40px;
			}
		}

		.Right {
			display: flex;
			align-items: center;

			img {
				margin-right: 34px;
			}

			button {
				font-weight: 500;
				font-size: 18.6182px;
				line-height: 28px;
				color: #ffffff;
				padding: 0 20px;
				height: 52.82px;
				border: none;
				background: #471cf1;
				border-radius: 155.152px;
			}
		}
	}
	.Body {
		flex: 1;
		overflow-y: scroll;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 40px;
		row-gap: 47px;
		padding-bottom: 50px;

		.Box {
			height: 446.57px;
			background: #191919;
			border-radius: 25.8133px;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			cursor: pointer;

			.Top {
				width: 100%;
				flex: 1;
				overflow: hidden;
				position: relative;
				z-index: 4;

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.5);
					z-index: 5;
				}

				.OptionBox {
					position: absolute;
					width: 128px;
					height: 70px;
					right: 40px;
					top: 35px;
					background: #191919;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 5px;
					z-index: 6;
					padding-top: 11px;
					padding-left: 10px;

					p {
						margin: 0;
						margin-bottom: 9px;
					}
				}

				img {
					width: 100%;
					height: 100%;
				}

				.threeDot {
					position: absolute;
					top: 28px;
					right: 26px;
					z-index: 6;
					font-size: 20px;
				}
			}
			.Bottom {
				width: 100%;
				height: 111px;
				padding: 17.21px 37px 24px 21.51px;

				h2 {
					font-weight: 500;
					font-size: 20.6506px;
					line-height: 31px;
					color: #ffffff;
					margin: 0;
				}

				div {
					display: flex;
					align-items: center;
					justify-content: space-between;

					p {
						font-weight: 400;
						font-size: 12.0462px;
						line-height: 18px;
						color: rgba(255, 255, 255, 0.5);
						margin: 0;
					}
					h3 {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						font-family: "Inter";
						font-style: normal;
						font-weight: 400;
						font-size: 20.6506px;
						line-height: 25px;
						color: #ffffff;
						margin: 0;

						span {
							font-weight: 400;
							font-size: 10.3253px;
							line-height: 12px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}

.LiveClassInfo {
	display: flex;
	align-items: center;
	column-gap: 63px;

	@media screen and (max-width: 1475px) {
		column-gap: 10px;
	}

	.LeftContainer {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;

		.TopBtnContainer {
			display: flex;
			justify-content: end;
			margin-bottom: 20px;

			button {
				padding: 0.5rem 2rem;
				border-radius: 138.268px;
				background: #471cf1;
				color: #fff;
				font-size: 16.592px;
				font-family: "Poppins";
				font-weight: 500;
				transition: all 0.2s ease-in;

				&:hover {
					background: #2000a2;
				}
			}
		}

		.VideoContainer {
			width: 100%;
			height: 325px;
			position: relative;
			margin-bottom: 25px;

			img {
				width: 100%;
				height: 100%;
			}

			.videoBottom {
				width: 100%;
				height: 75px;
				background: rgba(255, 255, 255, 0.02);
				backdrop-filter: blur(10px);
				position: absolute;
				left: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 25px 0 91px;
				overflow: hidden;

				.Left {
					h5 {
						color: #fff;
						font-size: 18px;
						font-family: Poppins;
						font-weight: 500;
						line-height: 104%;
						letter-spacing: 0.045px;
						margin-bottom: 10px;
					}
					h6 {
						color: rgba(153, 153, 153, 0.61);
						font-size: 10px;
						font-family: Poppins;
						line-height: 120%;
						letter-spacing: 0.025px;

						span {
							color: rgba(187, 163, 255, 1);
						}
					}
				}

				.Right {
					display: flex;
					align-items: center;
					justify-content: center;

					.Icon {
						cursor: pointer;
					}

					p {
						margin: 0;
						margin-left: 38px;

						span {
							margin-left: 10px;
						}
					}
				}
			}
		}

		.LectureContainer {
			flex: 1;
			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.LectureBox {
				width: 100%;
				min-height: 177px;
				margin-bottom: 15px;
				border-radius: 10px;
				background: #232222;
				position: relative;
				padding: 20px 13px 29px 28px;
				display: flex;
				align-items: center;
				gap: 34px;

				.Left {
					display: flex;
					width: 63px;
					height: 63px;
					padding: 18.224px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-radius: 100px;
					background: #471cf1;
					color: #fff;
					font-size: 25.514px;
					font-family: "Poppins";
					font-weight: 700;
					line-height: 120%;
					letter-spacing: 0.064px;
				}

				.Right {
					flex: 1;
					height: 100%;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					gap: 11px;

					.Top {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.TopLeft {
							display: flex;
							gap: 14px;

							h1 {
								color: #fff;
								font-size: 24px;
								font-family: "Poppins";
								margin: 0;
							}
							h6 {
								margin: 0;
								color: #fff;
								font-size: 12px;
								font-family: "Poppins";
								line-height: 24.829px;
								letter-spacing: -0.072px;
								align-self: end;
								span {
									margin-left: 5px;
								}
							}
						}

						.TopRight {
							display: flex;
							p {
								margin: 0;
								margin-right: 12.26px;
							}
							h6 {
								margin: 0;
								margin-left: 28.8px;
								margin-right: 20px;
							}
							.threeDot {
								cursor: pointer;
							}
						}
					}
					.Bottom {
						height: 100%;
						display: flex;
						gap: 9px;

						.BottomLeft {
							flex: 1;
							display: flex;
							flex-direction: column;
							gap: 11px;

							.p {
								flex: 1;
								overflow: hidden;
								color: rgba(255, 255, 255, 0.4);
								font-size: 12px;
								font-family: "Poppins";
								white-space: pre-wrap;
							}

							.BBB {
								display: flex;
								width: 292px;
								height: 22px;
								justify-content: center;
								align-items: center;
								gap: 19px;
								border-radius: 100px;
								background: rgba(255, 255, 255, 0.05);

								p {
									color: #fff;
									font-size: 12px;
									font-family: "Poppins";
									line-height: 12.688px;
									letter-spacing: -0.072px;
									margin: 0;
								}
								span {
									color: #666;
									font-size: 12px;
									font-family: "Poppins";
									margin: 0;
								}
							}
						}
						.BottomRight {
							display: flex;
							align-items: end;

							button {
								width: 148.706px;
								height: 49.169px;
								padding: 11.992px;
								border-radius: 5.996px;
								background: #333;
								color: #666;
								font-size: 14px;
								font-family: "Poppins";
								line-height: 24.829px;
								letter-spacing: -0.084px;
								cursor: pointer;
								transition: all 0.3s ease-in-out;

								&.start {
									background: #471cf1;
									color: #ffffff;
									&:hover {
										background: #2200a9;
									}
								}

								&.finish {
									background: #6e1010;
									color: #ffffff;
								}
							}
						}
					}
				}

				.ThreeBoxContainer {
					display: flex;
					width: 128px;
					padding: 11px 13px;
					flex-direction: column;
					align-items: flex-start;
					gap: 9px;
					border-radius: 5px;
					background: #191919;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					position: absolute;
					top: 24px;
					right: 28px;

					button {
						color: #fff;
						font-size: 14px;
						font-family: Poppins;
						border: none;
						cursor: pointer;
						background: transparent;
					}
				}
			}
		}
	}

	.RightContainer {
		flex: 1;
		height: 100%;
		overflow-y: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		.Top {
			width: 100%;
			min-height: 498px;
			background: #141414;
			margin-bottom: 40px;

			.Video {
				width: 100%;
				height: 375px;

				video {
					height: 100%;
					width: 100%;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}

			.TextContainer {
				padding: 42px 92px 50px 43px;
				display: flex;
				flex-direction: column;

				h1 {
					color: #fff;
					font-size: 38.358px;
					font-family: "Poppins";
					margin-bottom: 18px;

					span {
						margin-left: 22px;
						color: #fb3131;
						font-size: 19.179px;
						font-family: "Poppins";
						line-height: 39.683px;
						letter-spacing: -0.115px;
					}
				}

				p {
					margin-bottom: 18px;
					color: rgba(255, 255, 255, 0.4);
					font-size: 19.179px;
					font-family: "Poppins";
				}

				.BBB {
					display: flex;
					width: 466.694px;
					height: 35.162px;
					justify-content: center;
					align-items: center;
					gap: 30.367px;
					border-radius: 159.827px;
					background: rgba(255, 255, 255, 0.05);
					margin-bottom: 35px;

					p {
						color: #fff;
						font-size: 19.179px;
						font-family: "Poppins";
						margin: 0;
					}
					span {
						color: #666;
						font-size: 19.179px;
						font-family: "Poppins";
						margin: 0;
					}
				}

				.date {
					display: flex;
					margin-bottom: 49px;
					p {
						color: #fff;
						font-size: 27.237px;
						font-family: "Poppins";
						margin-right: 28px;
					}
					span {
						color: #fff;
						font-size: 26.667px;
						font-family: "Poppins";
						line-height: 28.196px;
						letter-spacing: -0.16px;
						margin-left: 64px;
					}
				}

				.ButtonContainer {
					display: flex;
					align-items: center;
					justify-content: space-between;

					button {
						display: flex;
						width: 231px;
						height: 59px;
						padding: 10px;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
						transition: all 0.3s ease-in-out;

						&:first-child {
							border-radius: 10px;
							border: 1px solid #666;
							color: #666;
							font-size: 16px;
							font-family: "Poppins";
							background: transparent;

							&:hover {
								background: #000;
							}
						}

						&:last-child {
							border-radius: 10px;
							background: #471cf1;
							color: #fff;
							font-size: 16px;
							font-family: "Poppins";

							&:hover {
								background: #1f009d;
							}
						}
					}
				}
			}
		}

		.Bottom {
			flex: 1;

			h3 {
				color: #fff;
				font-size: 24px;
				font-family: "Montserrat";
				font-weight: 500;
				line-height: 104%;
				letter-spacing: 0.06px;
				margin-left: 30px;
			}

			.Container {
				display: flex;
				width: 100%;
				padding: 17.504px 52.513px;
				justify-content: center;
				align-items: center;
				gap: 70.017px;

				.BoxContainer {
					display: flex;
					padding: 17.504px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 17.504px;

					.Box {
						width: 84.021px;
						height: 84.021px;
						border-radius: 84.021px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						background: var(--bgvar-1, linear-gradient(184deg, #4d1596 0%, #153996 100%));

						&.Support {
							background: linear-gradient(184deg, #313132 0%, #272727 95.83%);
						}
					}

					h6 {
						color: #fff;
						font-size: 24.506px;
						font-family: "Montserrat";
						font-weight: 500;
						line-height: 104%;
						letter-spacing: 0.061px;
					}
				}
			}
		}
	}
}

.LiveSection {
	display: flex;
	align-items: center;
	column-gap: 30px;
	overflow: hidden;

	@media screen and (max-width: 900px) {
		display: block;
		overflow-y: scroll;
	}

	.LeftSection {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: end;

		.Content {
			width: 600px;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.VideoContainer {
				flex: 1;
				position: relative;
				border-radius: 15px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
				video {
					width: 100%;
					height: 100%;
				}

				.VideoTop {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 44px 0 32px;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.2);
					backdrop-filter: blur(15px);

					.Left {
						display: flex;
						align-items: center;
						gap: 10px;

						.imageProfile {
							width: 71.687px;
							height: 71.687px;
							border-radius: 50%;
							overflow: hidden;
							padding: 8px;

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
							}
						}

						.textContender {
							display: flex;
							flex-direction: column;

							h5 {
								margin: 0;
								color: #fff;
								font-size: 25.428px;
								font-family: "Poppins";
							}
							p {
								margin: 0;
								color: rgba(255, 255, 255, 0.6);
								font-size: 13.87px;
								font-family: "Poppins";
							}
						}
					}

					.Right {
						display: flex;
						align-items: center;

						button {
							margin-right: 10px;
							color: #fff;
							font-size: 17.881px;
							font-family: "Poppins";
							height: 37.251px;
							padding: 0 12px;
							border-radius: 7.45px;
							transition: all 0.2s ease-in-out;

							&:first-child {
								background: #471cf1;
								border: 1.192px solid #471cf1;
								padding: 0 15px;

								&:hover {
									background: #2400b4;
								}
							}
							&:last-child {
								margin-right: 0px;
								border: 1.192px solid #fff;
								background: rgba(0, 0, 0, 0.1);
								backdrop-filter: blur(11.175332069396973px);

								&:hover {
									background: rgba(98, 98, 98, 0.5);
								}
							}
						}
					}
				}
			}

			.ActionContainer {
				padding-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 20px;

				.Round {
					width: 95px;
					height: 95px;
					border-radius: 50%;
					background: #101010;
					border: none;
					cursor: pointer;
					transition: all 0.3s ease-in-out;

					&:hover {
						background: #242424;
					}

					&.hangup {
						background: #d90000;

						&:hover {
							background: #800000;
						}
					}

					.Icon {
						color: #fff;
						font-size: 35px;
					}
				}
			}
		}
	}

	.RightSection {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;

		@media screen and (max-width: 900px) {
			height: 60%;
		}

		.Content {
			width: 100%;
			height: 100%;
			border-radius: 15px;
			background: #101010;
			padding: 20px 40px 50px 54px;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 900px) {
				padding: 10px 20px 30px 34px;
			}

			h1 {
				color: #fff;
				font-size: 29.801px;
				font-family: "Poppins";
				font-weight: 500;
				margin-bottom: 20px;

				@media screen and (max-width: 900px) {
					font-size: 20px;
					margin-bottom: 10px;
				}
			}

			.CommentContainer {
				flex: 1;
				margin-bottom: 10px;
				overflow-y: scroll;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					width: 4px;
				}
				&::-webkit-scrollbar-thumb {
					width: 5px;
					background: #471cf1;
					border-radius: 20px;
				}

				.CommentBox {
					display: flex;
					align-items: center;
					gap: 23px;
					margin-bottom: 32px;
					padding: 0 5px;
					@media screen and (max-width: 900px) {
						gap: 10px;
						margin-bottom: 10px;
						padding: 0 2px;
					}

					img {
						width: 67px;
						height: 67px;
						border-radius: 50%;

						@media screen and (max-width: 900px) {
							width: 50px;
							height: 50px;
						}
					}

					.textC {
						flex: 1;
						display: flex;
						flex-direction: column;
						h6 {
							margin: 0;
							color: #fff;
							font-size: 21.658px;
							font-family: "Poppins";
							@media screen and (max-width: 900px) {
								font-size: 17px;
							}
						}
						p {
							display: block;
							color: #fff;
							font-size: 16.8px;
							font-family: "Poppins";
							@media screen and (max-width: 900px) {
								font-size: 13px;
							}
						}
					}
				}
			}

			.CommentInputContainer {
				width: 100%;
				height: 78px;
				display: flex;
				align-items: center;
				gap: 18px;

				@media screen and (max-width: 900px) {
					gap: 10px;
				}

				.InputContainer {
					flex: 1;
					height: 77.482px;
					border-radius: 149.004px;
					background: #232323;
					overflow: hidden;
					padding-left: 40px;

					@media screen and (max-width: 900px) {
						height: 50px;
						border-radius: 149.004px;
						padding-left: 10px;
					}

					input {
						width: 100%;
						height: 100%;
						color: #fff;
						background: transparent;
						border: none;
						outline: none;
						padding: 0 25px;
						font-size: 20px;

						@media screen and (max-width: 900px) {
							padding: 0 15px;
							font-size: 18px;
						}
					}
				}

				button {
					width: 73.012px;
					height: 73.012px;
					border-radius: 149.004px;
					background: #471cf1;
					color: #fff;
					font-size: 30px;
					border: none;
					transition: all 0.2s ease-in-out;

					@media screen and (max-width: 900px) {
						width: 50px;
						height: 50px;
						font-size: 20px;
					}

					&:hover {
						background: #2200ab;
					}
				}
			}
		}
	}
}

.WatchLiveClass {
	height: 100vh;
	background: #000;
	position: relative;
	margin-left: 7%;
	padding: 3% 5%;
	overflow-y: scroll;
	display: flex;
	align-items: end;
	justify-content: center;
	gap: 30px;
	overflow: hidden;

	@media screen and (max-width: 1000px) {
		margin-left: 0%;
		display: block;
		padding: 3% 2%;
		overflow-y: scroll;
	}

	.LeftSection {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: end;

		.Content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.VideoContainer {
				flex: 1;
				position: relative;
				border-radius: 15px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
				video {
					width: 100%;
					height: 100%;
				}

				.VideoTop {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 44px 0 32px;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.2);
					backdrop-filter: blur(15px);

					@media screen and (max-width: 1000px) {
						padding: 0 24px 0 12px;
					}

					.Left {
						display: flex;
						align-items: center;
						gap: 10px;
						padding: 10px 0;

						.imageProfile {
							width: 71.687px;
							height: 71.687px;
							border-radius: 50%;
							overflow: hidden;
							padding: 8px;

							@media screen and (max-width: 1000px) {
								width: 50px;
								height: 50px;
								padding: 0px;
							}

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
							}
						}

						.textContender {
							display: flex;
							flex-direction: column;

							h5 {
								margin: 0;
								color: #fff;
								font-size: 25.428px;
								font-family: "Poppins";

								@media screen and (max-width: 1000px) {
									font-size: 18px;
								}
							}
							p {
								margin: 0;
								color: rgba(255, 255, 255, 0.6);
								font-size: 13.87px;
								font-family: "Poppins";

								@media screen and (max-width: 1000px) {
									font-size: 10px;
								}
							}
						}
					}

					.Right {
						display: flex;
						align-items: center;

						button {
							margin-right: 10px;
							color: #fff;
							font-size: 17.881px;
							font-family: "Poppins";
							height: 37.251px;
							padding: 0 12px;
							border-radius: 7.45px;
							transition: all 0.2s ease-in-out;

							@media screen and (max-width: 1000px) {
								height: 35px;
								padding: 0 10px;
							}

							&:first-child {
								background: #471cf1;
								border: 1.192px solid #471cf1;
								padding: 0 15px;

								&:hover {
									background: #2400b4;
								}
							}
							&:last-child {
								margin-right: 0px;
								border: 1.192px solid #fff;
								background: rgba(0, 0, 0, 0.1);
								backdrop-filter: blur(11.175332069396973px);

								&:hover {
									background: rgba(98, 98, 98, 0.5);
								}
							}
						}
					}
				}
			}

			.ActionContainer {
				padding-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 20px;

				.Round {
					width: 95px;
					height: 95px;
					border-radius: 50%;
					background: #101010;
					border: none;
					cursor: pointer;
					transition: all 0.3s ease-in-out;

					&:hover {
						background: #242424;
					}

					&.hangup {
						background: #d90000;

						&:hover {
							background: #800000;
						}
					}

					.Icon {
						color: #fff;
						font-size: 35px;
					}
				}
			}
		}
	}

	.RightSection {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;

		@media screen and (max-width: 1000px) {
			height: 60%;
		}

		.Content {
			width: 100%;
			height: 100%;
			border-radius: 15px;
			background: #101010;
			padding: 20px 40px 50px 54px;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 1000px) {
				padding: 10px 20px 20px 20px;
			}

			h1 {
				color: #fff;
				font-size: 29.801px;
				font-family: "Poppins";
				font-weight: 500;
				margin-bottom: 20px;

				@media screen and (max-width: 1000px) {
					font-size: 25px;
					margin-bottom: 10px;
				}
			}

			.CommentContainer {
				flex: 1;
				margin-bottom: 10px;
				overflow-y: scroll;
				scrollbar-width: none;

				@media screen and (max-width: 1000px) {
					margin-bottom: 5px;
				}

				&::-webkit-scrollbar {
					width: 4px;
				}
				&::-webkit-scrollbar-thumb {
					width: 5px;
					background: #471cf1;
					border-radius: 20px;
				}

				.CommentBox {
					display: flex;
					align-items: center;
					gap: 23px;
					margin-bottom: 32px;
					padding: 0 5px;

					@media screen and (max-width: 1000px) {
						gap: 15px;
						margin-bottom: 15px;
						padding: 0 5px;
					}

					img {
						width: 67px;
						height: 67px;
						border-radius: 50%;

						@media screen and (max-width: 1000px) {
							width: 50px;
							height: 50px;
						}
					}

					.textC {
						flex: 1;
						display: flex;
						flex-direction: column;
						h6 {
							margin: 0;
							color: #fff;
							font-size: 21.658px;
							font-family: "Poppins";

							@media screen and (max-width: 1000px) {
								font-size: 18px;
							}
						}
						p {
							display: block;
							color: #fff;
							font-size: 16.8px;
							font-family: "Poppins";

							@media screen and (max-width: 1000px) {
								font-size: 13px;
							}
						}
					}
				}
			}

			.CommentInputContainer {
				width: 100%;
				height: 78px;
				display: flex;
				align-items: center;
				gap: 18px;

				@media screen and (max-width: 1000px) {
					height: 50px;
				}

				.InputContainer {
					flex: 1;
					height: 100%;
					border-radius: 149.004px;
					background: #232323;
					overflow: hidden;
					padding-left: 40px;

					@media screen and (max-width: 1000px) {
						padding-left: 0px;
					}

					input {
						width: 100%;
						height: 100%;
						color: #fff;
						background: transparent;
						border: none;
						outline: none;
						padding: 0 25px;
						font-size: 20px;

						@media screen and (max-width: 1000px) {
							padding: 0 20px;
							font-size: 18px;
						}
					}
				}

				button {
					width: 73.012px;
					height: 73.012px;
					border-radius: 149.004px;
					background: #471cf1;
					color: #fff;
					font-size: 30px;
					border: none;
					transition: all 0.2s ease-in-out;
					
					@media screen and (max-width: 1000px) {
						width:50px;
						height:50px;
						font-size: 22px;
					}

					&:hover {
						background: #2200ab;
					}
				}
			}
		}
	}
}

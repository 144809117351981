.HomeMainContainer {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #000;
}

.MainContainer {
	background: linear-gradient(110.09deg, #000000 8.61%, #000000 98.6%);
	height: 100vh;
	overflow: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		border: 0;
		background: transparent;
		width: 0;
	}
}

.professionalMainContainer {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	stroke-width: none;
	display: flex;
	flex-direction: column;
	background: linear-gradient(110.09deg, #000000 8.61%, #000000 98.6%);
	color: whitesmoke;

	&::-webkit-scrollbar {
		display: none;
	}

	.ProfessionalBody {
		flex: 1;
		overflow: hidden;
		// border: 10px solid #f00;
		overflow-y: scroll;
		stroke-width: none;
		padding-left: 2%;
		padding-right: 2%;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 20px;
			background: #4d1596;
		}
	}

	.TextContent {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 50px;
	}
}

.professionalGetStartedButtonContainer {
	align-items: center;
	justify-content: space-between;
	width: 500px !important;
	height: 42px;

	button {
		height: 100%;
		padding: 0 15px;
		border-radius: 8px;
		cursor: pointer;
		border: none;
		background: #0d6efd;
		color: #fff;

		&.SIG {
			position: relative;

			div {
				width: 100% !important;
				height: 100% !important;
				position: absolute;
				top: 0;
				bottom: 0;
				left: -10px;
				opacity: 0;
			}
		}
	}
}

/* Professional-MyClass  or Learning*/
.MyClass .CreateClassButtonContainer {
	position: relative;
	display: flex;
	justify-content: end;
	padding: 10px 0;
}

.MyClass .CreateClassButton {
	background: linear-gradient(138.79deg, #4d1596 16.71%, #153996 72.98%);
	color: #fff;
	padding: 6px 25px;
	border-radius: 8px;
	box-shadow: 1px 1px 6px 5px rgba(255, 255, 255, 0.2);
}

.MyClass .CreateClassButton:hover {
	transition: all 0.3s ease-in-out;
	background: linear-gradient(138.79deg, #300a61 16.71%, #021c5e 72.98%);
}

.MyClass .MyClassBoxContainer {
	max-width: 100% !important;
	display: grid;
	/* grid-template-rows: repeat(10,180px); */
	grid-template-columns: repeat(3, auto);
	column-gap: 10px;
	row-gap: 15px;
	position: relative;
}

.MyClass .MyClassBox {
	display: flex;
	width: 100%;
	height: 180px;
	align-items: center;
	overflow: hidden;
	background: #1f1f1f;
	display: flex;
	cursor: pointer;
	z-index: 10;
	position: relative;
}

.MyClass .MyClassBox .LectureEditMenu {
	top: 10px;
	right: 1% !important;
	left: auto;
}

.MyClass .MyClassBox img {
	width: 40%;
	height: 100%;
}

.MyClass .MyClassBox .MyClassBoxInfo {
	width: 100%;
	height: 100%;
	padding: 20px 10px;
	display: flex;
	flex-direction: column;
}

.MyClass .MyClassBox .MyClassBoxInfo h6 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	margin-bottom: 12px;
	display: block;
}

.MyClass .MyClassBox .MyClassBoxInfo p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.0025em;
	color: #999999;
	margin-bottom: 12px;
	display: block;
	overflow: hidden;
	height: 100%;
}

.MyClass .MyClassBox .MyClassBoxInfo span {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.0025em;
	color: #888888;
	display: block;
}

.learning .home-right-mid .display-grid {
	max-width: 100% !important;
	display: grid;
	grid-template-rows: 180px;
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 10px;
}

.learning .home-right-mid .display-grid .home-right-mid-blocks {
	max-width: 100%;
	min-width: 100%;
	margin-left: 0%;
	overflow: hidden;
}

/* Header Popup */
.responsive-popup2 {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	backdrop-filter: blur(10px);
	z-index: 11;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	/* overflow-y: scroll; */
}

.responsive-popup-box2 {
	overflow-y: scroll;
	width: 1414px;
	margin: 0 auto;
	margin-top: 222px;
	display: grid;
	grid-template-rows: repeat(3, 156px);
	grid-template-columns: repeat(3, 428px);
	grid-column-gap: 62px;
	grid-row-gap: 30px;
	/* border: 1px solid #e4dcdc; */
}

.responsive-popup-box2::-webkit-scrollbar {
	display: none;
}

.responsive-popup2 .close-popup {
	top: 77px;
	right: 178.33px;
}

.responsive-popup-box2 .responsive-threeDotBox {
	display: flex;
	align-items: center;
	padding-left: 30px;
	background: #282828;
	border-bottom: 1px solid #2e2e2e;
	border-radius: 10px;
}

.responsive-popup-box2 .responsive-threeDotBox p {
	margin: 0%;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #ffffff;
}

/* Notification */
.notificationMainOuterContainer {
	border: 1px solid #fff;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
}
.responsive-notificationContainer {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	backdrop-filter: blur(10px);
	z-index: 110;
	overflow: hidden;
	left: 50%;
	right: 0;
	width: 50%;
	height: 100vh;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.responsive-notificationContainer .responsive-notification {
	width: 620px;
	margin: 0 auto;
	margin-top: 140px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.responsive-notificationContainer .responsive-notification .responsive-popup-header {
	width: 100%;
	height: 50px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.responsive-notification .responsive-popup-header p {
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 48px;
	color: #ffffff;
	margin: 0;
}

.responsive-notification .responsive-popup-header .responsive-close-popup2 {
	padding: 0 15px;
	height: 100%;
	border: none;
	background: transparent;
	color: #ffffff;
	font-weight: 700;
	font-size: 20px;
}

.responsive-notificationContainer .responsive-notification .responsive-popup-body {
	width: 100%;
	height: 580px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #ffffff;
	}

	.rp-notification {
		width: 100%;
		height: 90px;
		background: #2e2e2e;
		border-bottom: 1px solid #535353;
		border-radius: 10px;
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;

		p {
			margin: 0;
			font-size: 15px;
		}

		button {
			background: transparent;
			color: #fff;
		}
	}
}
.responsive-notification .responsive-clear-btn {
	/* width: 100px; */
	/* height: 24px; */
	padding: 5px 10px;
	margin: 0 auto;
	background: transparent;
	margin-top: 30px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	border: 1px solid #fff;
}

/* Professional-coursePurchase */
.learning .coursePurache {
	margin-left: 0%;
}

/* Professional-account */
.accountContainer .account {
	margin-right: -7%;
}

.accountContainer {
	padding-bottom: 50px;
}

.accountContainer .home-right-mid .apc {
	width: 100%;
	padding-right: 40px;
}

.accountContainer .home-right-mid .apc .apc-button {
	height: 35px;
	padding: 5px 15px;
	margin-left: 130px;
	margin-top: -40px;
	background: transparent;
	color: #fff;
	border: 1px solid #fcfcfc56;
	border-radius: 7px;
	transition: all 0.2s ease-in-out;
}

.accountContainer .home-right-mid .apc .apc-button:hover {
	background: #42f;
}

.accountContainer .PostImgCon {
	width: 100%;
	min-height: 400px;
}

.postAndAlbumContainer {
	position: relative;
	width: 500px;
	height: 500px;
	overflow: hidden;
	overflow-y: scroll;
}

.postAndAlbumContainer::-webkit-scrollbar {
	border: 0;
	width: 0;
	background: transparent;
}

/* .accountContainer .postAndAlbumContainer img,
video {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
} */

/* createPostModel */
.createPostModel {
	width: 800px !important;
	height: 100vh !important;
	margin-top: 150px !important;
}

.createPostModel .modal-content {
	border: none;
	outline: none;
	background: transparent;
}

.cpr {
	width: 600px !important;
	margin: 0 auto !important;
	border-radius: 20px;
	overflow: hidden;
	z-index: 99999;
	display: flex;
	flex-direction: column;
	background: rgba(25, 25, 25, 1);
	/* height: 195px; */
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 3%;
	width: 600px;
	margin: 0;
}

.cpr .cpr-header {
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
}

.cpr .cpr-header p {
	margin: 0;
	color: #999;
}

.cpr .cpr-header input {
	width: 100%;
	background-color: #333;
	padding: 5%;
	border-radius: 10px;
	margin-top: 1%;
	border: none;
	color: #fff;
}

.cpr .cpr-header textarea {
	width: 100%;
	background-color: #333;
	padding: 5%;
	border-radius: 10px;
	margin-top: 1%;
	border: none;
	color: #fff;
}

.cpr .cpr-body {
	justify-content: space-evenly;
}

.cpr .cpr-body .create-post-mobile-btn {
	border-radius: 20px;
	height: 35px;
	border: 2px solid gray;
	transition: all 0.2s ease-in-out;
}

.cpr .cpr-body .create-post-mobile-btn .cpr-btn {
	height: 100%;
	width: 100%;
	padding: 0px 15px;
	color: #ffffff;
	background: transparent;
	transition: all 0.2s ease-in-out;
}

.cpr .cpr-body .create-post-mobile-btn:hover {
	background: rgb(20, 101, 168);
	border: 2px solid rgb(20, 101, 168);
	color: white !important;
}

/* Professional-profile-update */
.Professional-profile-update .profilePicContainer {
	width: 100%;
	height: 80px;
	display: flex;
}

.Professional-profile-update .profilePicContainer .pictureContainer {
	width: 150px;
	height: 150px;
	margin-top: -75px;
	overflow: hidden;
	border-radius: 50%;
}

.Professional-profile-update .profilePicContainer .pictureContainer img {
	width: 100%;
	height: 100%;
}

.Professional-profile-update .profilePicContainer .upload-btn-wrapper {
	width: 50px;
	height: 50px;
	overflow: hidden;
	cursor: pointer;
	padding: 10px;
	margin-top: 28px;
}

.Professional-profile-update .upload-btn-wrapper img {
	width: 100%;
	height: 100%;
}

.Professional-profile-update .profilePicContainer .upload-btn-wrapper input {
	display: none;
}

/* professional-getStarted */
.googleBtn {
	width: 165px;
	height: 100%;
	margin-left: 4%;
}

/* Like Button Component */
.heart.yt_like_btn {
	position: relative;
	border-radius: 10em;
	height: 20px !important;
	user-select: none;
	padding-bottom: 5px;
	text-align: center;
	transition-duration: 0.2s;
	background: transparent;
	-webkit-transition-duration: 0.2s;
}

.heart.yt_like_btn:after {
	content: "";
	display: block;
	position: absolute;
	border-radius: 10em;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 2s;
	box-shadow: 0 0 10px 40px white;
}

.heart.yt_like_btn:active:after {
	box-shadow: 0 0 0 0 white;
	position: absolute;
	border-radius: 10em;
	left: 0;
	top: 0;
	opacity: 1;
	transition: 0s;
}

.heart.yt_like_btn:active {
	top: 1px;
}

.main-crop-container {
	position: absolute;
	width: 400px;
	height: 500px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999999;
	display: flex;
	flex-direction: column;
}

.cropActionBox1 {
	max-height: 400px !important;
	background: rgb(255, 255, 255);
	overflow: hidden;
}

.reactEasyCrop_Container {
	max-height: 400px !important;
}

.cropActionBox2 {
	max-height: 400px !important;
	flex-direction: column !important;
	align-items: normal !important;
	z-index: 44444;
	background: #ffffff;
	padding: 10px 30px !important;
}

.btn-cancle {
	border: 1px solid red !important;
	color: red;
}

.btn-crop {
	margin-left: 5% !important;
	border: 1px solid green !important;
	color: green;
}
.comments {
	padding-top: 10px;
}

.align {
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;

	.mainComment {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		position: relative;
		padding: 0;

		.cmtPostUserName {
			flex: 1;
			display: flex;
			flex-direction: column;
			// background: #fff;

			h5 {
				margin: 0;
				font-size: 15px;
			}
			p {
				display: block;
				width: 100%;
				margin: 0;
			}

			.innerPDiv {
				display: flex;
				align-items: center;
			}
		}

		.replyCMT {
			width: 50px;
			height: 20px;
			background: rgba(255, 255, 255, 0.2);
			border-radius: 5px;
			font-weight: 400;
			font-size: 9px;
			line-height: 10px;
			color: #ffffff;
		}
	}

	.commentThreeDot {
		min-width: 10px;
		color: #fff;
		background: transparent;
		margin-left: 6px;
		cursor: pointer;
	}

	.cmtThreeDotPopup {
		position: absolute;
		top: 0;
		right: 10px;
		background: #000;
		// height: 50px;
		width: 130px;
		box-shadow: 0 0 8px 1px rgba(255, 255, 255, 0.5);
		display: flex;
		flex-direction: column;
		gap: 5px;

		button {
			width: 100%;
			padding: 5px;
			border: none;
			background: transparent;
			font-size: 14px;
			color: #fff;
		}
	}

	.cmtReplyContainer {
		margin-bottom: 10px;
		margin-left: auto;
		width: 80%;
		display: flex;
		align-items: center;
		gap: 10px;
		position: relative;

		.cmtReplyTextBox {
			flex: 1;
		}

		h6 {
			margin: 0;
			font-size: 15px;
		}
		p {
			margin: 0;
		}
	}

	.showLessBtn {
		color: #2763fc;
		width: 100px;
		background: transparent;
		margin-left: 50px;
		font-size: 14px;
		margin-top: 10px;
	}
}

.postProfileImageContainer {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	overflow: hidden;
}

.postProfileImageContainer img {
	border-radius: 0% !important;
	height: 100% !important;
	width: 100% !important;
	transform: scale(1.2);
	margin: 0px !important;
	padding: 0px !important;
	border: none !important;
	z-index: 55;
}

/* User Home */
.home .home-right2 .home-right-top {
	position: relative;
	left: 0px;
	top: 0px;
}

.my-custom-clear-btn {
	position: absolute;
	top: -3px;
	right: 5px;
	overflow: hidden;
	cursor: pointer;
	margin: 2%;
	color: #525050;
	width: 100.51px;
	height: 35px;
	max-height: 35px;
	border-radius: 67.0096px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.ScribbleButtonMainContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// padding-right: 18px;
}

.ScribbleButtonContainer {
	height: 58px;
	display: flex;
	align-items: center;
}

.ScribbleButtonInput {
	background: transparent;
}

.ScribbleButtonInput:hover {
	border: none;
	background: rgb(20, 101, 168);
}

/* User Album */
.UserMainContainer {
	height: 100vh !important;
}

.UserMainContainer .albumBody {
	height: 100vh;
	background: #000;
	position: relative;
	margin-left: 7%;
	padding-left: 2%;
	padding-right: 2%;
	overflow-y: scroll;
	padding-bottom: 5%;
}
.UserMainContainer .albumBody::-webkit-scrollbar {
	width: 8px;
}

.UserMainContainer .albumBody::-webkit-scrollbar-thumb {
	background: #42f;
	border-radius: 10px;
}

.UserMainContainer .albumBody .FirstContainer {
	background: #000;
	padding-top: 3.5%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	position: sticky;
	top: 0;
	right: 0;
}

.albumBody .FirstContainer h1 {
	color: #fff;
	font-size: 32px;
}

.albumBody .FirstContainer div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.albumBody .FirstContainer div img {
	height: 24px;
	margin-right: 20px;
	cursor: pointer;
}

.albumBody .FirstContainer div button {
	background-color: #471cf1;
	color: #fff;
	border-radius: 20px;
	padding: 6px 25px;
}

.albumBody .FirstContainer div button:active {
	transition: all 0.1s ease-in-out;
	background: #1e0096;
}

.albumBody .SecondContainer {
	/* margin-bottom: 20px; */
}

.albumBody .SecondContainer p {
	color: #999;
	font-size: 12px;
	margin-bottom: 6px;
}

.albumBody .SecondContainer .AlbumItems {
	color: #999;
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 15px;
	width: 100%;
	height: 160px;
	overflow: hidden;
	// overflow-x: auto;
	/* height: 305px; */
	/* flex-wrap: wrap; */
	padding-bottom: 10px;
}

.SecondContainer .AlbumItems::-webkit-scrollbar {
	height: 8px;
}

.SecondContainer .AlbumItems::-webkit-scrollbar-thumb {
	background: #42f;
	border-radius: 10px;
}

.albumBody .SecondContainer .AlbumItems .albumItem {
	min-width: 160px;
	max-width: 160px;
	height: 140px;
	overflow: hidden;
	border-radius: 10px;
	user-select: none;
	cursor: pointer;
}
.albumBody .SecondContainer .AlbumItems .albumItem:active {
	cursor: grabbing;
}

.albumBody .SecondContainer .AlbumItems .albumItem img {
	width: 100%;
	height: 100%;
}

.AlbumPagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-top: 10px;
}
.AlbumPagination button {
	border: none;
	padding: 5px 20px;
	background: transparent;
	color: #42f;
	font-weight: 700;
	font-size: 19px;
	transition: all 0.3s ease-in-out;
	border-radius: 10px;
}
.AlbumPagination button:hover {
	background: #757575;
}
.AlbumPagination button:disabled {
	color: #757575;
}

.albumBody .ThirdContainer {
	margin-top: 25px;
}

.albumBody .ThirdContainer .musicBar {
	background-color: #302c2c;
	color: #fff;
	border-radius: 100px;
	height: 62px;
	width: 578px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	position: relative;
	overflow: hidden;
	z-index: 3;
	user-select: none;
}

.ThirdContainer .playerProgress {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #471cf1;
	z-index: 4;
	transition: all 0.2s ease-in-out;
}

.albumBody .ThirdContainer .musicBar:active .playerProgress {
	background: #1e0096;
}

.albumBody .ThirdContainer .musicBar:hover {
	background: #272323;
}

.ThirdContainer .musicBar .musicBarLeft {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	z-index: 5;
}

.ThirdContainer .musicBar .musicBarLeftImg {
	border-radius: 50%;
	height: 50px;
	width: 50px;
	overflow: hidden;
	background: #d9d9d9;
	color: #000;
}

.ThirdContainer .musicBar .musicBarLeft .musicBarLeftImg img {
	height: 100%;
	width: 100%;
}

.ThirdContainer .musicBar .musicBarLeft .musicBarLeftImg #thumbnailPic {
	animation: rotateThumbnailPic infinite 3s linear;
}

@keyframes rotateThumbnailPic {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.ThirdContainer .musicBar .musicBarMiddle {
	width: 100px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	z-index: 4;
}

.ThirdContainer .musicBar .musicBarMiddle p {
	margin-bottom: 0;
	text-align: center;
}

.ThirdContainer .musicBar .musicBarLeft .musicBarLeftText h5 {
	font-size: 18px;
	margin-bottom: 0;
}

.ThirdContainer .musicBar .musicBarLeft .musicBarLeftText p {
	font-size: 12px;
	color: #e4e4e4;
	margin-bottom: 0;
}

.ThirdContainer .musicBar .musicBarRight {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	z-index: 5;
}

.ThirdContainer .musicBar .musicBarRight div.palyPause {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #e8e8e844;
	cursor: pointer;
}

/* 
.ThirdContainer .musicBar .musicBarRight div:first-child {
	background: transparent;
} */

/* Profile edit */

.ProfileUpdateUserMain {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	scrollbar-width: none;
	background: linear-gradient(153.39deg, #2b2b2b 16.89%, #2b2b2b 83.31%);

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: #4d1596;
	}

	.ProfileUpdateUser {
		margin-left: 7%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;

		.coverImage {
			height: 250px;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
			overflow: hidden;
			position: relative;

			.coverImg {
				width: 105%;
				margin-left: -10px;
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 9px;
				position: absolute;
				width: 120px;
				right: 22px;
				top: 15px;
				border: 0.723577px solid #ffffff;
				border-radius: 3.61789px;
				background: transparent;
				font-family: "Poppins";
				font-style: normal;
				font-weight: 500;
				font-size: 10.1301px;
				line-height: 15px;
				color: #ffffff;
				transition: all 0.2s ease-in-out;

				&:hover {
					background: #471cf1;
					border: 0.723577px solid #471cf1;
				}

				&:active {
					background: #b5a7f0;
					color: #000000;
					border: 0.723577px solid #ffffff;
				}
			}

			.changeCoverPhoto {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				cursor: pointer;

				p {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					color: #ffffff;
				}

				img {
					height: 22px;
					width: 22px;
					margin-bottom: 7px;
				}
			}
		}
	}
}

.ProfileUpdateUser .updateFieldsMainContainer {
	flex: 1;
	padding: 38px 86px 98px 108px;

	h1 {
		font-weight: 400;
		font-size: 30px;
		line-height: 45px;
		color: #ffffff;
		margin-bottom: 0;
	}

	.updateTopSection {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: -25px;

		.profileImage {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.changeProfileBtnContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 47px;

			button {
				background: transparent;
				border: none;
				outline: none;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #ffffff;
				border-radius: 5px;
				align-items: center;
				padding: 10px 20px;
				height: 41;
				border: 1px solid #ffffff;
				transition: all 0.2s ease-in-out;

				&:active {
					background: #b5a7f0;
					color: #000000;
				}

				&:last-child {
					background: #471cf1;
					border: 1px solid #471cf1;
				}

				&:last-child:active {
					background: #3200fc;
					border: 1px solid #3200fc;
					color: #fff;
				}
			}
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: #ffffff;
			margin-top: 15px;
		}
	}

	.updateBottomSection {
		margin-top: 65px;
		display: flex;
		gap: 200px;
		padding-left: 80px;

		.updateLeft {
			height: 100%;
			width: 400px;
		}

		.updateRight {
			height: 100%;
			width: 400px;
		}

		.groupContainer {
			height: 49px;
			position: relative;
			margin-bottom: 36px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&.AboutYou {
				height: 137px;
			}

			.inputParent {
				height: 100%;
				width: 100%;
				background: #2b2b2b;
				border: 1px solid #959393;
				border-radius: 10px;
				overflow: hidden;
				display: flex;
				align-items: center;
				padding: 0 12px;

				label {
					position: absolute;
					top: -10px;
					left: 15px;
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #ffffff;
					background: #2b2b2b;
					padding: 0 10px;
				}

				&:focus-within {
					transition: all 0.2s ease-in-out;
					border: 1px solid #471cf1;
				}

				input {
					height: 100%;
					width: 100%;
					border: none;
					outline-color: transparent;
					outline: none;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #fff;
					background: #2b2b2b;
					padding: 0 10px;

					&::placeholder {
						font-size: 14px;
						line-height: 21px;
						color: #959393;
					}
				}
			}

			&.twoItems .inputParent {
				width: 45%;
			}

			&.twoItems .inputParent.rightInput label {
				position: absolute;
				top: -10px;
				left: 58%;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: #ffffff;
				background: #2b2b2b;
				padding: 0 10px;
			}

			&.twoItems .labelContainer {
				width: 52%;

				label {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #ffffff;
					margin-left: 10px;

					input {
						margin-right: 5px;
					}
				}
			}

			.uploadProfileBtn {
				width: 100%;
				height: 100%;
				background: #471cf1;
				border-radius: 10px;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.2s ease-in-out;

				&:active {
					background: #3200fc;
				}

				&:hover {
					background: #2e00fc;
				}
			}
		}

		.AboutYou .inputParent textarea {
			height: 100%;
			width: 100%;
			border: none;
			outline-color: transparent;
			outline: none;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #fff;
			background: #2b2b2b;
			padding: 15px 10px;
			resize: none;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 5px;
			}
		}
	}
}

/* Create Album Popup */
.albumPopup {
	position: absolute;
	top: 0;
	right: 0;
	width: 473.19px;
	height: 100%;
	background: #010101;
	z-index: 10;

	.closeAlbumBtn {
		position: absolute;
		width: 26px;
		height: 97.78px;
		left: -26px;
		bottom: 200px;
		background: #471cf1;
		border-radius: 10px 0px 0px 10px;
		z-index: 20;
		transition: all 0.1s ease-in-out;

		&:hover {
			background: #6749df;
		}

		&:active {
			background: #1e0096;
		}
	}
}

.albumPopup .innerAlbumPopup {
	height: 100%;
	position: relative;
	padding-top: 45px;
	overflow: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: #4d1596;
	}
}

.albumPopup .albumHeading {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	text-align: center;
}

.albumPopup .albumCoverPictureDiv {
	margin-top: 55px !important;
	width: 350.11px;
	height: 184.4px;
	background: rgba(48, 48, 48, 0.54);
	border: 1px solid #3c3c3c;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	cursor: pointer;
	user-select: none;
	overflow: hidden;
}

.albumPopup .albumCoverPictureDiv .coverPic {
	width: 100%;
	height: 100%;
}

.albumPopup .albumCoverPictureDiv h3 {
	margin-top: 9px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.albumPopup .inputGroup {
	width: 342px;
	height: 79px;
	margin: 0 auto;
	margin-top: 37px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 21.6px;
}

.albumPopup .inputGroup label {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #999999;
	padding: 10px 13px;
}

.albumPopup .inputGroup input,
.albumPopup .inputGroup textarea {
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	padding: 10px 15px;
	font-weight: 500;
	font-size: 16px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	border: none;
	outline: none;
	background: transparent;
	border-bottom: 1px solid #3c3c3c;
}

.albumPopup #InfoContainer {
	min-height: 250px;
}

.albumPopup .inputGroup:last-child {
	height: 130px;
}

.albumPopup .inputGroup textarea {
	resize: none;
	width: 100%;
	height: 100%;
}

.albumPopup .albumVideoAudio {
	width: 347.35px;
	height: 180px;
	border: 1px solid #555555;
	border-radius: 10px;
	margin: 0 auto;
	cursor: pointer;
	user-select: none;
	overflow: hidden;
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.albumPopup .albumVideoAudio h3 {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #494949;
	margin-top: 19px;
}

.albumPopup .btnContainer {
	margin-top: 120px;
	width: 100%;
	padding: 0 10px;
	bottom: 5px;
}

.albumPopup .createAlbumBtn {
	width: 100%;
	height: 66px;
	background: #471cf1;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #166928;
	}

	p {
		margin-bottom: 0;
		color: #fff;
		z-index: 10;
	}
}

/* AlbumPopup */
.innerAlbumPopup .albumHeader {
	position: relative;
}

.innerAlbumPopup .albumHeader .albumHBgCont {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 77.46%);
	height: 235.17px;
	overflow: hidden;
	position: relative;
	z-index: 5;
}

.innerAlbumPopup .albumHeader .albumHBgCont img {
	height: 100%;
	width: 100%;
}

.innerAlbumPopup .albumHeader .albumHBgCont .albumDeleteBtn {
	position: absolute;
	top: 10px;
	right: 10px;
	background: #fff;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 9;
	transition: all 0.2s ease-in-out;
}

.innerAlbumPopup .albumHeader .albumHBgCont .albumDeleteBtn:hover {
	background: #aaaaaa;
}

.innerAlbumPopup .albumHeader .albumHBg {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 77.46%);
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
}

.innerAlbumPopup .albumHeader .albumHTextCont {
	position: relative;
	margin-top: -90px;
	padding: 0 60px 0 28px;
	z-index: 6 !important;
}

.albumHeader .albumHTextCont .textAndPlay {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.albumHeader .albumHTextCont .textAndPlay div {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #471cf1;
	cursor: pointer;
}

.albumHeader .albumHTextCont .textAndPlay h1 {
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	margin-bottom: 0;
}

.albumHeader .albumHTextCont .byAuthor {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #999999;
	margin-top: -5px;
}

.albumHeader .albumHTextCont .byAuthor span {
	color: rgba(171, 150, 255, 1);
}

.albumHeader .albumHTextCont .albumDec {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #999999;
	margin-bottom: 0;
}

.innerAlbumPopup .otherAlbumBody {
	width: 100%;
	height: 100%;
	margin-top: 33px;
	overflow-y: scroll;
	padding: 0 20px;
	position: relative;
	scrollbar-width: none;
}

.innerAlbumPopup .otherAlbumBody::-webkit-scrollbar {
	width: 6px;
}

.innerAlbumPopup .otherAlbumBody::-webkit-scrollbar-thumb {
	background-color: #471cf1;
	border-radius: 10px;
}

.otherAlbumBody .otherAlbum {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #fefefe;
	padding-left: 38px;
	padding-bottom: 5px;
	position: sticky;
	top: 0;
	background: #000;
	z-index: 5;
}

.otherAlbumBody .albumItemContainer {
	border-bottom: 1px solid rgba(60, 60, 60, 1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 40px;
	cursor: pointer;
	position: relative;
}

.otherAlbumBody .albumItemContainer .albumItemContainerLeft {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 11px;
}

.albumItemContainer .albumItemContainerLeft .imgAlbumOther {
	width: 48px;
	height: 48px;
	background: #d9d9d9;
	overflow: hidden;
	border-radius: 50%;
}

.albumItemContainer .albumItemContainerLeft .imgAlbumOther img {
	width: 100%;
	height: 100%;
}

.albumItemContainer .albumItemContainerLeft .aTextB {
	height: 100%;
}

.albumItemContainer .albumItemContainerLeft .aTextB h3 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #999999;
	margin-bottom: 0;
}

.albumItemContainer .albumItemContainerLeft .aTextB div {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6.9px;
}

.albumItemContainer .albumItemContainerLeft .aTextB span {
	display: block;
	width: 33.8px;
	height: 18.28px;
	font-style: normal;
	font-weight: 400;
	font-size: 6.8977px;
	line-height: 10px;
	color: #999999;
	background: #393939;
	border-radius: 68.977px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.otherAlbumBody .albumItemContainer .albumItemContainerRight {
	user-select: none;
}

.otherAlbumBody .albumItemContainer .albumItemContainerRight span {
	color: #989898;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}

.otherAlbumBody .albumItemContainer .albumItemContainerRight .AlbumItemPopUp {
	width: 70px;
	height: 90%;
	position: absolute;
	top: 10px;
	right: 60px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.albumItemContainerRight .AlbumItemPopUp button {
	width: 100%;
	background: #000000;
	color: #fff;
	box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.2);
	padding: 5px;
	transition: all 0.2s ease-in-out;
}

.albumItemContainerRight .AlbumItemPopUp button:hover {
	background: #222222;
}

.LoginUserContainer {
	padding: 31px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.LoginUserContainer .passContainer {
	width: 100%;
	position: relative;

	.lockLogin {
		position: absolute;
		left: 10%;
		top: 60%;
		transform: translateY(-50%);
	}

	.eye-pass {
		padding-left: 30px;
	}
}

.LoginUserContainer .passContainer .eyeImage {
	height: 15px;
	position: absolute;
	top: 60%;
	transform: translateY(-50%);
	right: 10%;
}

.LoginUserContainer .CheckBoxContainer {
	width: 85%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.LoginUserContainer .CheckBoxContainer .check-box-remember {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 3px;
}

.LoginUserContainer .LogCheck {
	height: 15px !important;
	width: 15px !important;
}

.LoginUserContainer .loginWithEmail {
	width: 85% !important;
}

.card .loginWithGoogle {
	width: 84% !important;
	position: relative;
	background: #471cf1;
	color: #fff;
}
.card .loginWithGoogle div {
	width: 100% !important;
	height: 100% !important;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}

.RegisterUserContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.RegisterUserContainer .regDivUser {
	width: 100%;
	position: relative;
}

.RegisterUserContainer .regDivUser .eye-register {
	top: 60%;
	right: 15%;
	transform: translateY(-50%);
}

/* ClassInfo */
.ClassInfo {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.ClassInfo .ClassInfoContainer {
	margin: 0 auto;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	scrollbar-width: none;
	display: flex;
	flex-direction: row;
	gap: 24px;
}

.ClassInfoContainer::-webkit-scrollbar {
	display: none;
}

.InfoContainer {
	width: 500px;
	height: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1234;
	background: linear-gradient(138.79deg, #4d1596 16.71%, #153996 72.98%);
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 24.5061px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	border-radius: 20px;
}

.InfoContainer button {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	background: linear-gradient(138.79deg, #313132 16.71%, #272727 70.64%);
	position: absolute;
	top: -25px;
	right: -25px;
}

.InfoContainer .InfoContainerInner {
	width: 100%;
	height: 100%;
	padding: 20px;
	border-radius: 20px;
	overflow-y: scroll;
	scrollbar-width: none;
}

.InfoContainer .InfoContainerInner::-webkit-scrollbar {
	display: none;
}

.SupportContainer {
	background: linear-gradient(138.79deg, #313132 16.71%, #272727 70.64%);
}

.SupportContainer button {
	background: linear-gradient(138.79deg, #4d1596 16.71%, #153996 72.98%);
}

.ClassInfoLeft {
	width: 110%;
	min-height: 650px;
	display: flex;
	overflow: hidden;
	flex-direction: column;
}

.ClassInfoLeftVideoContainer {
	width: 100%;
	height: 268px;
	margin-bottom: 24px;
	position: relative;
	overflow: hidden;
}

.ClassInfoLeftVideoContainer video {
	width: 100%;
	height: 100%;
}

.ClassInfoLeftVideoContainer .ClassInfoLeftVideoContainerOverlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) -43.39%, #000000 87.61%);
}

.ClassInfoLeftVideoContainer > img,
.ClassInfoLeftVideoContainer > video {
	width: 100%;
	height: 100%;
}

.ClassInfoLeftVideoContainer .controlsContainer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	padding: 0 91px 30px 89px;
}

.controlsContainer .controlsContainerBottom {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.controlsContainerBottom h1 {
	font-weight: 500;
	font-size: 30px;
	color: #ffffff;
	margin-bottom: 0;
	cursor: pointer;
	padding: 0 5px;
}

.controlsContainerBottom .h2 {
	h3 {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 104%;
		letter-spacing: 0.0025em;
		color: #ffffff;
		margin-bottom: 0;
	}
	h4 {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 104%;
		letter-spacing: 0.0025em;
		color: #ffffff;
		margin-top: 10px;
		margin-bottom: 0;
		padding-left: 5px;
	}
	p {
		margin-top: 10px;
		font-weight: 400;
		font-size: 10px;
		line-height: 120%;
		letter-spacing: 0.0025em;
		color: rgba(153, 153, 153, 0.61);

		span {
			color: rgba(187, 163, 255, 1);
		}
	}
}

.ClassInfoLeftLectureContainer {
	width: 100%;
	flex: 1;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) -43.39%, #000000 87.61%);
	scrollbar-width: none;
	overflow-y: scroll;
	padding-bottom: 10px;
}

.ClassInfoLeftLectureContainer::-webkit-scrollbar {
	display: none;
}

.ClassInfoLeftLectureContainer .LectureBox {
	width: 100%;
	height: 122px;
	background: #1c1c1c;
	border-bottom: 1.82243px solid #3a3a3a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 75px 0 85px;
	position: relative;
	/* transition: all .1s ease-in-out; */
}

.ClassInfoLeftLectureContainer .LectureBox:hover {
	background: #282828;
}

.LectureBox svg {
	cursor: pointer;
}

.LectureBox:hover svg path {
	fill: #666666;
}

.activeLectureBox {
	background: #282828 !important;
}

.activeLectureBox svg path {
	fill: #666666;
}

.LectureBox .LectureBoxLeft {
	display: flex;
	align-items: center;
	gap: 36px;
	width: 100%;
}

.LectureBox .LectureBoxLeft h1 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 25.514px;
	line-height: 120%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	margin-bottom: 0;
}

.LectureBox .LectureBoxLeft div {
	display: flex;
	flex-direction: column;
	gap: 7px;
	min-width: 65%;
}

.LectureBox .LectureBoxLeft div p {
	font-weight: 400;
	font-size: 18.2243px;
	line-height: 120%;
	color: rgba(146, 146, 146, 0.61);
	margin-bottom: 0;
}

.LectureBox .LectureBoxLeft div span {
	display: block;
	width: 100%;
	height: 14.58px;
	background: rgba(217, 217, 217, 0.14);
	border-radius: 182.243px;
	position: relative;
	overflow: hidden;
}

.LectureBox .LectureBoxLeft div span p {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background: #7544ff;
}

.LectureBox .LectureBoxRight {
	display: flex;
	align-items: center;
	gap: 53px;
}

.LectureBox .LectureBoxRight button {
	background: transparent;
	border: none;
	outline: none;
}

.LectureEditMenu {
	width: 100px;
	height: 100px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	position: absolute;
	top: 10px;
	left: 50%;
	z-index: 20;
}

.LectureEditMenu button {
	z-index: 21;
	border: none;
	cursor: pointer;
	width: 100%;
	height: 100%;
	background: #1c1c1c;
	color: #fff;
	box-shadow: 0 0 8px 3px rgba(255, 255, 255, 0.2);
}

.LectureEditMenu button:active {
	box-shadow: inset 0 0 8px 3px rgba(255, 255, 255, 0.2);
}

.Lecture {
	margin-top: 0px !important;
	height: 400px !important;
}

.ClassInfoRight {
	width: 90%;
	display: flex;
	flex-direction: column;
	min-height: 650px;
}

.ClassInfoRight h1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.ClassInfoRight h1 span {
	color: rgba(64, 64, 64, 1);
}

.ClassInfoRight .RoundBoxContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 51px;
	margin-bottom: 40px;
}

.ClassInfoRight .OverallRatingText {
	margin-bottom: 40px;
	display: flex;
	align-items: center;
}

.ClassInfoRight .OverallRatingText img {
	transform: scale(1.5);
	margin-left: 30px;
	margin-right: 15px;
	margin-bottom: 5px;
}

.RoundBoxContainer .RoundBoxDetail {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.RoundBoxContainer .RoundBoxDetail h2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 24.5061px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #ffffff;
	margin-top: 17px;
	margin-bottom: 0;
}

.RoundBoxContainer .RoundBox {
	width: 84px;
	height: 84px;
	border-radius: 50%;
	background: linear-gradient(138.79deg, #4d1596 16.71%, #153996 72.98%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.RoundBoxContainer .RoundBoxDetail:last-child .RoundBox {
	background: linear-gradient(138.79deg, #313132 16.71%, #272727 70.64%);
}

.CommentBoxContainer {
	overflow-y: scroll;
	max-height: 460px;
	flex: 1;
	padding: 0 125px;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.commentBox {
		width: 100%;
		margin-bottom: 39px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.commentBoxLeft {
			display: flex;
			align-items: center;
			gap: 19px;
			overflow: hidden;

			img {
				width: 56px;
				height: 56px;
				border-radius: 50%;
			}

			.cmtTextCont {
				display: flex;
				flex-direction: column;

				h3 {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 500;
					font-size: 15px;
					line-height: 104%;
					letter-spacing: 0.0025em;
					color: #ffffff;
					margin-bottom: 0;
					cursor: pointer;
					margin-bottom: 5px;
				}
				h4 {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 104%;
					letter-spacing: 0.0025em;
					color: #ffffff;
					margin-bottom: 0;
				}
			}
		}

		.commentBoxRight {
			display: flex;
			align-items: center;
			gap: 6px;

			img {
				width: 21.52px;
				height: 21.52px;
			}
			p {
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 400;
				font-size: 14.5805px;
				line-height: 104%;
				letter-spacing: 0.0025em;
				color: #ffffff;
				margin-bottom: 0;
			}

			.commentDeleteIcon {
				margin-left: 20px;
				font-size: 25px;
				color: #f00;
				cursor: pointer;
			}
		}
	}
}

/* Create Class  Popup */
.modal {
	height: 220% !important;
}

.modal-backdrop.show {
	background: rgba(34, 34, 34, 0.8) !important;
	backdrop-filter: blur(5px) !important;
	opacity: 1 !important;
}

.CreateClassContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background: rgba(34, 34, 34, 0.8) !important;
	backdrop-filter: blur(5px) !important;
	z-index: 11111;
}

.CreateClassMain {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 590px;
	background: transparent;
	z-index: 1111;
	// margin-top: 50px;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;

	@media screen and (max-height: 840px) {
		height: 100vh;
		padding-bottom: 100px;
	}
}

.CreateClassMain::-webkit-scrollbar {
	display: none;
}

.CreateClassMain .CreateClassTitle {
	position: relative;
}

.CreateClassMain .CreateClassTitle h1 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 63px;
	color: #ffffff;
	margin-bottom: 0;
}

.CreateClassMain .CreateClassTitle h4 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #999999;
	margin-bottom: 10px;
}

.CreateClassMain .CreateClassTitle button {
	height: 30px;
	width: 30px;
	background: transparent;
	position: absolute;
	top: 25px;
	right: 25px;
}

.CreateClassMain .CreateClassTitle button:active {
	transition: all 0.1s ease-in-out;
	transform: scale(0.7);
}

.CreateClassMain .ContentFields {
	width: 100%;
	height: 68px;
	background: #272727;
	border: 0.862516px solid #252525;
	border-radius: 8.62516px;
	margin-bottom: 11px;
	overflow: hidden;
}

.CreateClassMain .ContentFields input,
.CreateClassMain .ContentFields textarea {
	width: 100% !important;
	height: 100% !important;
	background: #272727;
	color: #fff;
	margin: 0 !important;
	padding: 10px 40px !important;
	border-radius: 0;
	border: none;
	outline: none;
	resize: none;
	scrollbar-width: thin;
}

.CreateClassMain .ContentFields.c1 {
	height: 200px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.CreateClassMain .ContentFields.c2 {
	height: 120px;
	border-radius: 20px;
}

.CreateClassMain .ContentFields.c3 {
	height: 90px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.CreateClassMain .ContentFields.c3 button {
	min-width: 160px;
	height: 49.53px;
	background: #272727;
	border: 1px solid #d8caca;
	border-radius: 40px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 15.24px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #7e7e7e;
}

.CreateClassMain .ContentFields.c3 button:hover {
	background: #471cf1;
	color: #fff;
}

.CreateClassMain .ContentFields.c3 button:hover svg path {
	fill: #fff;
}

.CreateClassMain .ContentFields.c1 img {
	height: 100%;
	width: 100%;
}

.CreateClassMain .ContentFields.c1 h5 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: #666666;
}

.CreateClassMain .CourseBtn {
	width: 100%;
	height: 62px;
	background: #471cf1;
	border-radius: 8.62516px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.CreateClassMain .CourseBtn span {
	width: 0%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #00aeffe1;
	z-index: 2;
}

.CreateClassMain .CourseBtn p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	overflow: hidden;
	z-index: 3;
	margin-bottom: 0;
}

/* MyClass */
// .MyClass {
// }

/* User Notification */
.UserNotification {
	background: rgba(0, 0, 0, 0.6);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 999;

	.UserNotificationPopupBox {
		width: 900px;
		height: 60%;
		position: absolute;
		top: 20%;
		left: 10%;
		color: #fff;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.UserNotificationBox {
			height: 100%;
			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.loading {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.notificationBoxDiv {
				width: 100%;
				height: 60px;
				background: #2b2b2b;
				border-bottom: 1px solid #767676;
				margin-bottom: 10px;
				padding: 0 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.1);
				cursor: pointer;

				p {
					margin: 0;
				}

				button {
					background: transparent;
					color: #fff;
				}
			}
		}
	}
}

/* ShareScreen */
.SharePostScreen {
	width: 100%;
	height: 100vh;
	background: #111111;
	display: flex;
	padding: 110px 127px;
	gap: 119px;
	scrollbar-width: none;
	overflow-y: scroll;
}

.SharePostScreen::-webkit-scrollbar {
	display: none;
}

.SharePostScreen .SharePostLeft {
	width: 361px;
}

.SharePostScreen .imageVideoContainer {
	width: 100%;
}

.SharePostScreen .SharePostImageContainer {
	width: 100%;
	/* max-height: 515px; */
	max-height: auto;
	overflow: hidden;
	margin-bottom: 12px;
	border-radius: 15px;
	box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.1);
}

.SharePostScreen .SharePostImageContainer img {
	width: 100%;
	height: auto;
}

.SharePostScreen .SharePostImageContainer video {
	width: 100%;
	height: auto;
}

.SharePostScreen .SharePostImageContainer audio {
	width: 100%;
}

.SharePostScreen .imageVideoContainer p {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #ffffff;
	margin-bottom: 0;
}

.SharePostScreen .textContainer {
	// width: 361px;
	// min-height: 100px;
	// overflow: hidden;
	// margin-bottom: 12px;
	// border-radius: 15px;
	// padding: 15px 20px;
	// font-family: "Inter";
	// font-style: normal;
	// font-weight: 400;
	// font-size: 18px;
	// line-height: 15px;
	scrollbar-width: none;
	box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.1);
	background: rgba(30, 28, 28, 0.55);
	width: 400px;
	min-height: 100px;
	max-height: 515px;
	border-radius: 10px;
	padding: 20px;
	overflow-y: scroll;
	white-space: pre-wrap;
	text-align: justify;
	font-size: 20px;
	color: #f5f5f5;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background: #0d6efd;
		border-radius: 10px;
	}

	button {
		background: transparent;
		color: rgb(13, 202, 240);
		font-weight: 600;
		cursor: pointer;
	}
}

.SharePostScreen .ShareProfileControls {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.SharePostScreen .ShareProfileControlsLeft {
	height: 40px !important;
	display: flex;
	align-items: center;
	gap: 14px;
	
	.sharePContWrapper{
		height: 100%;
		overflow: hidden;

		.shareRatingCont{
			display: flex;
			
			p{
				padding-left: 10px;
			}

		}
		
	}
}

.SharePostScreen .ShareProfileControlsLeft p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	margin-bottom: 0;
}

.SharePostScreen .ShareProfileControlsLeft img {
	width: 40px;
	height: 100%;
	border-radius: 50%;
}

.SharePostScreen .ShareProfileControlsRight {
	display: flex;
	align-items: center;
	gap: 14px;
}

.SharePostScreen .ShareLike {
	display: flex;
	align-items: center;
	width: 45px;
}

.SharePostScreen .ShareLike p {
	margin-bottom: 0;
	margin-left: 11px;
	padding-top: 10px;
}

.SharePostScreen .ShareLike .ShareHeart {
	background: transparent;
	font-size: 20px;
	cursor: pointer;
}

.SharePostScreen .ShareIconContainer {
	cursor: pointer;
}

.SharePostScreen .SharePostRight {
	width: 376px;
}

.SharePostScreen .SharePostRight h1 {
	margin-bottom: 18px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	color: #ffffff;
}

.SharePostScreen .SharePostCommentsContainer {
	width: 100%;
	max-height: 350px;
	overflow-y: scroll;
	scrollbar-width: none;
}

.SharePostScreen .SharePostCommentsContainer::-webkit-scrollbar {
	display: none;
}

.SharePostScreen .SharePostComment {
	width: 100%;
	min-height: 65px;
	max-height: 100px;
	display: flex;
	align-items: center;
	gap: 19px;
	margin-bottom: 10px;
}

.SharePostScreen .SharePostComment div {
	width: 120px;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.SharePostScreen .SharePostComment div p {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	text-align: center;
}

.SharePostScreen .SharePostComment img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.SharePostScreen .SharePostComment p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 11.3553px;
	line-height: 17px;
	color: #ffffff;
	margin-bottom: 0;
	display: block;
	width: 100%;
	height: 91%;
	overflow: hidden;
}

.SharePostScreen .SharePostWriteBox {
	width: 100%;
	height: 52px;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.SharePostScreen .SharePostWriteBox input {
	width: 100%;
	height: 100%;
	background: #333333;
	padding: 10px;
	border: none;
	color: #fff;
	outline: none;
}

.SharePostScreen .SharePostWriteBox button {
	width: 64px;
	height: 100%;
	background: #471cf1;
	color: #fff;
}

/* UsersProfile */
.UsersProfileDetailsContainer {
	width: 50%;
	position: relative;
}

.UsersProfileDetailsContainer .ProfilePicContainer {
	margin-bottom: 10px;
}

.UsersProfileDetailsContainer .UserProfileNameContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 40px;
}

.UsersProfileDetailsContainer .ProfileButtonContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

/* PostComponent */
.PostMainContainer {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #eee;
	margin-bottom: 40px;
	width: 400px;
	position: relative;
	backdrop-filter: blur(10px);

	// @media screen and (max-width: 1300px) {
	// 	margin: 0;
	// }
}

.PostProfileContainer {
	width: 100%;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px 0 5px;
}

.PostProfileContainerLeft {
	display: flex;
	align-items: center;
	gap: 6px;
	flex: 1;
}

.PostProfileImageContainer {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	padding: 2px;
	/* background-color: transparent; */
	/* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23EB00FFFF' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e"); */
	/* background: #fff; */
	cursor: pointer;
	position: relative;
}

.PostProfileImageContainer .circImg {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	z-index: 5;
	padding: 3px;
	background: #000;
	overflow: hidden;
}

.PostProfileImageContainer img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.circ {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
	z-index: 4;
}

.sect {
	height: 0px;
	width: 0px;
	position: absolute;
	top: 0;
	right: 0;
	border-right: 25px solid transparent;
	border-top: 25px solid transparent;
	transform-origin: bottom left;
}

.sect:nth-child(1) {
	transform: rotate(0deg);
	border-right: 25px solid transparent;
}

.sect:nth-child(2) {
	transform: rotate(36deg);
	border-right: 25px solid rgba(255, 168, 0, 0.2);
}

.sect:nth-child(3) {
	transform: rotate(72deg);
	border-right: 25px solid transparent;
}

.sect:nth-child(4) {
	transform: rotate(108deg);
	border-right: 25px solid rgba(255, 168, 0, 0.2);
}

.sect:nth-child(5) {
	transform: rotate(144deg);
	border-right: 25px solid transparent;
}

.sect:nth-child(6) {
	transform: rotate(180deg);
	border-right: 25px solid rgba(255, 168, 0, 0.2);
}

.sect:nth-child(7) {
	transform: rotate(216deg);
	border-right: 25px solid transparent;
}

.sect:nth-child(8) {
	transform: rotate(252deg);
	border-right: 25px solid rgba(255, 168, 0, 0.2);
}

.sect:nth-child(9) {
	transform: rotate(288deg);
	border-right: 25px solid transparent;
}

.sect:nth-child(10) {
	transform: rotate(324deg);
	border-right: 25px solid rgba(255, 168, 0, 0.2);
}

.PostProfileStarTextContainer {
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	flex: 1;
	overflow: hidden;
}

.PostProfileStarContainer {
	width: 100%;
	display: flex;
	align-items: flex-start;
	align-items: center;

	& img {
		width: 17px;
		user-select: none;
	}

	& p {
		margin-bottom: 0;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 13.5px;
		line-height: 11px;
		color: rgb(255, 255, 255);
		margin-left: 10px;
		cursor: pointer;
	}
}

.PostProfileContainerLeft .PostProfileText {
	margin-bottom: 0;
	cursor: pointer;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 13.8787px;
	line-height: 21px;
	color: #ffffff;
}

.PostProfileContainerRight {
	height: 50px;
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	// border: 1px solid #00f;
}

.PostProfileContainerRight {
	.PostComment {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2px;
		cursor: pointer;
		user-select: none;
	}

	.PostDotShare {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2px;
		cursor: pointer;
		user-select: none;
	}
}

.PostProfileContainerRight div img {
	height: 70%;
}

.PostProfileContainerRight div p {
	margin-bottom: 0;
	user-select: none;
	min-width: 25px;
	text-align: center;
}

.ProfileCaptionContainer {
	width: 100%;
	white-space: pre-wrap;
	padding: 10px;
	text-align: justify;

	&.vtc {
		padding-top: 0 !important;
	}
}

.ProfileCaptionContainer button {
	background: transparent;
	color: rgb(13, 202, 240);
	font-weight: 600;
	cursor: pointer;
}

.onlyCaption {
	background: rgba(30, 28, 28, 0.55);
	width: 100%;
	min-height: 100px;
	border-radius: 10px;
	padding: 20px;
}

.PostStar {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;

	p {
		color: #fff;
		margin: 0;
		user-select: none;
		min-width: 25px;
		text-align: center;
	}
}

.PostDotShare img {
	width: 27px;
}

.RatingPopupContainer {
	width: 170px;
	height: 35px;
	background: #262626;
	border-radius: 5px;
	position: absolute;
	top: -40px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	visibility: hidden;
	z-index: 10;
	opacity: 0;
	transition: all 0.2s ease-in;
}

.RatingPopupContainer::after {
	content: "";
	width: 9.53px;
	height: 9.53px;
	position: absolute;
	bottom: -5px;
	left: 9px;
	background: #262626;
	border-radius: 0px 0px 0px 3px;
	transform: matrix(0.67, -0.74, 0.67, 0.74, 0, 0);
}

.RatingPopupContainer::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: -12px;
	left: -10px;
	background: transparent;
	border-radius: 0px 0px 0px 3px;
}

.RatingPopupContainer div {
	margin: 0 2px;
	z-index: 15;
	transition: all 0.1s ease-in-out;
}

.RatingPopupContainer div img {
	transition: all 0.1s ease-in-out;
	width: 25px;
}

.RatingPopupContainer div:nth-child(1):hover img {
	fill: #ffa800;
	transform: scale(1.1);
}

.RatingPopupContainer div:nth-child(2):hover img {
	fill: #ffa800;
	transform: scale(1.2);
}

.RatingPopupContainer div:nth-child(3):hover img {
	fill: #ffa800;
	transform: scale(1.3);
}

.RatingPopupContainer div:nth-child(4):hover img {
	fill: #ffa800;
	transform: scale(1.4);
}

.RatingPopupContainer div:nth-child(5):hover img {
	fill: #ffa800;
	transform: scale(1.5);
}

.PostStar .starImg {
	transition: all 0.2s ease-in-out;
	font-size: 23px;
	margin-right: 2px;
	width: 25px;
}

.PostStar:hover .starImg {
	transform: scale(1.2);
}

.PostStar:hover .RatingPopupContainer {
	visibility: visible;
	opacity: 1;
}

.ReportPostContainer {
	padding: 0 5px;
	cursor: pointer;
	user-select: none;
}

// User Three Dot Popup
.ThreeDotPopupForOtherUser {
	width: 200px;
	background: black;
	font-size: 12.5px;
	color: gray;
	position: absolute;
	bottom: 0px;
	right: 0px;
	border-radius: 10px;
	z-index: 10;
	border: 1px solid gray;
	display: flex;
	flex-direction: column;

	button {
		position: absolute;
		top: 0px;
		right: 0;
		width: 22px;
		height: 22px;
		color: red;
		font-size: 15px;
		font-weight: bold;
		background: transparent;
		border: 0 !important;
		padding: 2%;
		cursor: pointer;
	}

	div {
		display: flex;
		justify-content: center;
		cursor: pointer;
		padding: 6px;
		border-bottom: 0.5px solid gray;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}

/* ProfileImagePopup */
.ProfileImagePopup {
	position: absolute;
	width: 280px;
	height: 300px;
	/* top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	bottom: 60px;
	left: 10%;
	z-index: 1000;
}

.ProfileImagePopup button {
	position: absolute;
	width: 50px;
	height: 50px;
	top: -25px;
	right: -25px;
	background: #fff;
	color: rgb(13, 202, 240);
	border-radius: 50%;
	box-shadow: 0 0 7px 4px rgba(13, 202, 240, 0.2);
	font-size: 25px;
	font-weight: 700;
}

.ProfileImagePopupImage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 20px;
	box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.4);
}

.ProfileImagePopupImage img {
	width: 100%;
	height: 100%;
	padding: 0;
}

/* Active Hours */
.ActiveHours {
	display: flex;
	flex-direction: column;
	color: #fff;
}

.ActiveHours .textContainer h2 {
	padding: 4% 0 0 60px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	margin-bottom: 0;
}

.ActiveHours .textContainer h3 {
	padding: 0 60px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #999999;
	margin-bottom: 0;
}

.ActiveHours .textContainer h1 {
	text-align: center;
	font-style: normal;
	font-weight: 600;
	font-size: 55px;
	margin-bottom: 0;
}

.ActiveHours .textContainer h6 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
	text-align: center;
	color: #5f5f5f;
	margin-bottom: 0;
}

.ActiveHours .ChartContainer {
	height: 400px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 20px;
}

.ActiveHours .ChartColumnContainer {
	width: 80px;
}

.ChartColumnContainer .ChartTime {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #999999;
	text-align: center;
	margin: 10px;
}

.ChartColumnContainer .ChartDiv {
	background: linear-gradient(180deg, #6000dd 0%, #9000b4 100%);
	border-radius: 5px;
}

.ChartColumnContainer .ChartDay {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 104%;
	letter-spacing: 0.0025em;
	color: #999999;
	text-align: center;
	margin: 10px;
}

/* User Learning */

.UserLearning {
	color: whitesmoke;
	margin-left: 7%;
	padding-left: 2%;
	height: 100vh;
	overflow-y: scroll;
	scrollbar-width: none;
	padding-bottom: 20px;
}

.UserLearning::-webkit-scrollbar {
	display: none;
}

.UserLearning .LearningHeading {
	margin-top: 53px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 28px;
}

.UserLearning .LearningHeading h5 {
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.UserLearning .LearningUserCarousel {
	display: flex;
	border: 1px solid #22e610;
	margin-bottom: 25px;
}

.UserLearning .EnrollCourseMainContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;

	h5 {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.0025em;
		color: #999999;
		margin-bottom: 15px;
	}

	.EnrolledCourseContainer {
		display: grid;
		column-gap: 13px;
		row-gap: 13px;
		grid-template-columns: repeat(3, 1fr);

		.EnrolledCourseBox {
			height: 180px;
			min-width: 364px;
			border-radius: 0px;
			background: #1f1f1f;
			cursor: pointer;
			display: flex;
			overflow: hidden;

			.EnrolledCourseBoxImageCont {
				height: 100%;
				width: 40%;

				img {
					height: 100%;
					width: 100%;
				}
			}

			.EnrolledCourseBoxTextCont {
				height: 100%;
				width: 60%;
				padding: 2%;
				position: relative;

				span {
					width: 100%;
					padding: 2%;
					position: absolute;
					bottom: 0;
					right: 0;
					background: #1f1f1f;
				}
			}
		}
	}
}

.UserLearning .LearningClassMainContainer {
	display: flex;
	flex-direction: column;
}

.UserLearning .LearningClassMainContainer h5 {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.0025em;
	color: #999999;
	margin-bottom: 15px;
}

.UserLearning .LearningClassContainer {
	display: grid;
	column-gap: 13px;
	row-gap: 13px;
	grid-template-columns: repeat(3, 1fr);
}

.UserLearning .LearningClassContainer .LearningClassBox {
	height: 180px;
	min-width: 364px;
	border-radius: 0px;
	background: #1f1f1f;
	cursor: pointer;
	display: flex;
	overflow: hidden;
}

.UserLearning .LearningClassBox .LearningClassBoxImageCont {
	height: 100%;
	width: 40%;
}

.UserLearning .LearningClassBox .LearningClassBoxImageCont img {
	height: 100%;
	width: 100%;
}

.UserLearning .LearningClassBox .LearningClassBoxTextCont {
	height: 100%;
	width: 60%;
	padding: 2%;
	position: relative;
}

.UserLearning .LearningClassBox .LearningClassBoxTextCont span {
	width: 100%;
	padding: 2%;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #1f1f1f;
}

/* User CourseDetails */

.UserCourseDetails {
	color: whitesmoke;
	margin-left: 7%;
	padding-left: 2%;
	height: 100vh;
	overflow-y: scroll;
	scrollbar-width: none;
	padding-bottom: 20px;
}

.UserCourseDetails::-webkit-scrollbar {
	display: none;
}

.UserCourseDetails .UserCourseDetailsTopCont {
	position: relative;
	height: 400px;
	z-index: 3;
}

.UserCourseDetails .UserCourseDetailsTopCont .UserCourseDetailsTopContOverlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 3;
}

.UserCourseDetailsTopContImg {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.UserCourseDetails .UserCourseDetailsTopCont h2 {
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
	letter-spacing: 0.0025em;
	color: #ffffff;
	position: relative;
	padding-left: 50px;
	padding-top: 50px;
}

.UserCourseDetails .UserCourseDetailsTopContInner {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding-left: 50px;
	z-index: 5;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner1 {
	display: flex;
	align-items: center;
	gap: 7px;
	margin-bottom: 8px;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner1 button {
	min-width: 83px;
	height: 41px;
	left: 209px;
	top: 239px;
	background: #2f2f2f;
	border-radius: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner1 button:last-child {
	background: #471cf1;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding-right: 40px;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner2 h3 {
	font-weight: 400;
	font-size: 26px;
	line-height: 39px;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner2 button {
	width: 190px;
	height: 41px;
	background: #471cf1;
	border-radius: 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.0025em;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner2 button:hover {
	background: #2400b3;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner2 button:disabled {
	background: #4c4958;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner3 {
	display: flex;
	align-items: center;
	gap: 27px;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner3 p {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner3 h5 {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: 0.0025em;
	color: #ffffff;
}

.UserCourseDetailsTopContInner .UserCourseDetailsTopContInner3 h5 span {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: 0.0025em;
	color: rgba(142, 221, 255, 1);
	cursor: pointer;
}

.UserCourseDetailsBottomCont {
	margin-top: 25px;
	flex: 1;
	display: flex;
	padding-left: 20px;
	gap: 50px;
}

.UserCourseDetailsBottomCont .ReviewPostInClass {
	display: flex;
	align-items: center;
	padding-right: 10px;
	margin-bottom: 20px;
	gap: 5px;
}

.UserCourseDetailsBottomCont .ReviewPostInClass input {
	color: #fff;
	background: transparent;
	border: none;
	outline: none;
	font-size: 18px;
	padding: 10px;
	flex: 1;
}

.UserCourseDetailsBottomCont .ReviewPostInClass select {
	cursor: pointer;
	color: #fff;
	background: transparent;
	border: none;
	outline: none;
	font-size: 18px;
	padding: 0 5px;
}

.UserCourseDetailsBottomCont .ReviewPostInClass select option {
	color: #000000;
}

.UserCourseDetailsBottomCont .ReviewPostInClass button {
	color: #fff;
	border: none;
	outline: none;
	font-size: 18px;
	padding: 10px 20px;
	background: linear-gradient(138.79deg, #4d1596 16.71%, #153996 72.98%);
	border-radius: 10px;
}

.UserCourseDetailsBottomCont .ReviewPostInClass button:disabled {
	background: #2e2e2e;
}

.UserCourseDetails .ClassInfoRight {
	height: 800px;
}

.RatingMessageContainer {
	display: flex;
	gap: 20px;
	margin-bottom: 16px;
	.RatingContainer {
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 6px 0px;
		background: #2e00fc;
		border-radius: 20px;
	}
	.MessageContainer {
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 6px 0px;
		background: #2e00fc;
		border-radius: 20px;
		cursor: pointer;
	}
}

.PostContainerImage {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

@media screen and (min-width: 1400px) {
	/* landingPage */
	.landing-img-1 {
		width: 100%;
		background-position: center center;
	}

	.landing-img-2 {
		width: 100%;
		background-position: center center;
	}

	/* Professional-learning */
	.BasicStyle-professional.learning {
		height: 93.6vh;
	}

	/* Professional-instructor */
	.learning .instructorHead {
		margin: 0%;
	}
}

@media screen and (max-width: 1300px) {
	/* professional-login */
	.professional-login .left {
		margin-top: 250px;
	}

	.professional-login .professional-card {
		left: 90px;
		top: 80px;
	}

	/* Professional-learning */
	.BasicStyle-professional.learning {
		padding-left: 1%;
		padding-right: 1%;
		padding-bottom: 50px;
	}

	.learning .justify-arrond .home-right {
		margin-left: 0%;
	}

	.learning .justify-arrond img {
		width: 100%;
		height: auto;
	}

	/* .MyClass .MyClassBoxContainer {
		grid-template-rows: repeat(1, 180px);
	} */

	.learning .home-right-mid .display-grid {
		grid-template-rows: repeat(1, 180px);
	}

	/* Header Popup */
	.responsive-popup2 .close-popup {
		top: 77px;
		right: 70px;
	}

	/* Notification */
	.responsive-popup2.responsive-notificationContainer {
		left: 35%;
		width: 65%;
	}

	/* ClassInfo */
	.ClassInfo .ClassInfoContainer {
		width: 100%;
	}

	/* ShareScreen */
	.SharePostScreen {
		width: 100%;
		height: 100vh;
		padding: 110px 80px;
		gap: 80px;
		padding-bottom: 100px;
	}

	/* UserSearchBar */
	.UserSearchBarPopupBox {
		top: 0%;
		left: 10%;
		transform: translate(0%, 0%);
	}

	/* User Learning */
	.UserLearning .LearningClassContainer {
		grid-template-columns: repeat(2, 1fr);
	}

	.UserLearning .LearningClassContainer .LearningClassBox {
		height: 200px;
		min-width: 364px;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
	/* Header Popup */
	.responsive-popup-box2 {
		width: auto;
		margin-top: 200px;
		grid-template-rows: repeat(3, 156px);
		grid-template-columns: repeat(3, minmax(328px, 1fr));
		grid-column-gap: 30px;
		grid-row-gap: 20px;
	}

	.responsive-popup2 .close-popup {
		top: clamp(50px, 10%, 100px);
		right: clamp(20px, 10%, 50px);
	}

	/* Professional-instructor */
	.learning .instructorHead {
		margin: 0%;
	}

	/* Professional-coursePurchase */
	.accountContainer .home-right-mid .apc .apcB {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 1050px) {
	/* Professional-coursePurchase */
	.accountContainer .home-right-mid > .display-flex {
		flex-direction: column;
	}

	.accountContainer .home-right-mid .apc {
		max-width: 100% !important;
	}

	.accountContainer .home-right-mid .PostImgCon {
		padding-bottom: 40px;
	}

	.accountContainer .home-right-mid .PostImgCon img {
		width: 100%;
		padding: 0 20px;
	}

	/* NOtification */
	.UserNotification .UserNotificationPopupBox {
		width: 800px;
		position: absolute;
		top: 40%;
		left: 5%;
		transform: translate(-0%, -50%);
		color: #fff;
	}

	/* UserSearchBar */
	.UserSearchBarPopupBox {
		top: 0%;
		left: 2%;
		transform: translate(0%, 0%);
	}
}

@media screen and (max-width: 1000px) {
	/* landingPage */
	.landing {
		flex-direction: column;
	}

	.landing-img-1 {
		width: 100%;
		height: 80vh;
		background-position: center center;
	}

	.landing-img-2 {
		width: 100%;
		height: 80vh;
		background-position: center center;
	}

	/* professional-getStarted */
	.Professional-GetStarted {
		background-position: center center;
	}

	/* professional-login */
	.professional-login {
		flex-direction: column;
		background-position: -100px center;
	}

	.professional-login .left {
		margin: 80px auto 0;
	}

	.professional-login .professional-card {
		top: 50px;
		left: 50%;
		transform: translateX(-50%);
	}

	/* Professional-learning */
	.learning .justify-arrond {
		flex-direction: column;
	}

	.learning .justify-arrond .home-right {
		margin-top: 10px;
	}

	.MyClass .MyClassBoxContainer {
		/* grid-template-rows: repeat(2, 180px); */
		grid-template-columns: repeat(2, auto);
		grid-row-gap: 20px;
	}

	.learning .home-right-mid .display-grid {
		grid-template-rows: repeat(2, 180px);
		grid-template-columns: repeat(2, auto);
		grid-row-gap: 20px;
	}

	/* Header Popup */
	.responsive-popup-box2 {
		width: auto !important;
		height: calc(100% - 70px);
		overflow-y: scroll;
		margin: 70px 100px 0;
		grid-template-columns: repeat(2, minmax(228px, 1fr));
		grid-template-rows: repeat(5, 156px);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	}

	.responsive-popup2 .close-popup {
		top: 20px;
		right: 40px;
	}

	/* Notification */
	.responsive-popup2.responsive-notificationContainer {
		left: 15%;
		width: 85%;
	}

	/* Professional-instructor */
	.learning .instructorHead {
		margin: 0%;
	}

	.learning .text-muted {
		padding: 0 5px;
	}

	/* ClassInfo */
	.ClassInfo .ClassInfoContainer {
		display: block;
		height: 100vh;
		padding-bottom: 50px;
	}

	.ClassInfoLeft {
		width: 100%;
		margin-bottom: 30px;
	}

	.ClassInfoLeftVideoContainer {
		height: 195px;
	}

	.ClassInfoRight {
		width: 100%;
		padding: 0 10px;
	}

	.ClassInfoLeftVideoContainer .controlsContainer {
		padding: 0 60px 15px 60px;
	}

	.CommentBoxContainer {
		padding: 0 50px;
	}

	/* Notification */
	.UserNotification {
		top: 0px;
		left: 0px;
		margin-top: 0%;
		max-width: 100%;
		height: 100vh;
	}

	.UserNotification .UserNotificationPopupBox {
		width: 100% !important;
		top: 10%;
		left: 0%;
		transform: translate(-0%, 0%);
	}

	/* ShareScreen */
	.SharePostScreen {
		width: 100%;
		height: 100vh;
		padding: 50px 80px 50px;
		gap: 20px;
		flex-direction: column;
		padding-bottom: 100px;
	}

	.SharePostScreen .SharePostLeft {
		width: 100%;
	}

	.SharePostScreen .SharePostRight {
		width: 100%;
	}
}

@media screen and (max-width: 750px) {
	/* Professional-learning */
	.MyClass .MyClassBoxContainer {
		/* grid-template-rows: repeat(3, 180px); */
		grid-template-columns: repeat(1, auto);
	}

	.learning .home-right-mid .display-grid {
		grid-template-rows: repeat(3, 180px);
		grid-template-columns: repeat(1, auto);
	}

	/* Header Popup */
	.responsive-popup-box2 {
		margin: 70px 70px 0;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
	}

	/* Notification */
	.responsive-popup2.responsive-notificationContainer {
		left: 0%;
		width: 100%;
	}

	.responsive-notificationContainer .responsive-notification {
		width: 600px;
		margin-top: 130px;
	}

	/* ShareScreen */
	.SharePostScreen {
		width: 100%;
		height: 100%;
		padding: 30px 30px 20px;
		gap: 20px;
		flex-direction: column;
		padding-bottom: 100px;
	}
}

@media screen and (max-width: 602px) {
	/* landingPage */
	.landing-content {
		margin: 0 auto;
		margin-top: 20%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.landing-content h3 {
		max-width: auto;
		margin-top: 100px;
		margin-left: 0%;
	}

	/* professional-getStarted */
	.Professional-GetStarted .header {
		flex-direction: column;
		align-items: center;
		gap: 30px;
		height: auto;
	}

	/* professional-login */

	.professional-login .left {
		margin: 50px auto 0;
	}

	.professional-login .card {
		margin-left: 0 !important;
		width: 100%;
	}

	.professional-login .eye {
		top: 43%;
	}

	.professional-login .card input[type="checkbox"] {
		margin-left: 5px !important;
	}

	/* Professional-register1 & Professional-register2 */
	.professional-login .Registercard .card-body .d1 {
		/* border: 1px solid #000; */
		margin-left: 25px !important;
	}

	.professional-login .Registercard .card-body .d2 {
		/* border: 1px solid #000; */
		margin-left: 23px !important;
	}

	.professional-login .Registercard.card input[type="checkbox"] {
		margin-left: 15px !important;
	}

	/* Header Popup */
	.responsive-popup-box2 {
		height: calc(100% - 80px);
		margin: 80px 50px 0;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(9, 80px);
		grid-column-gap: 0px;
		grid-row-gap: 10px;
	}

	/* Notification */
	.responsive-notificationContainer .responsive-notification {
		max-width: 410px !important;
		margin-top: 120px;
	}

	/* Professional-coursePurchase */
	.learning .coursePurache {
		justify-content: center;
		padding-left: 0%;
	}

	.learning .coursePurache .display-flex {
		flex-direction: column;
		gap: 20px;
	}

	.learning .coursePurache .display-flex button {
		width: 100%;
		padding: 10px;
	}

	/* ClassInfo */
	.ClassInfoLeftLectureContainer .LectureBox {
		padding: 0 15px 0 10px;
	}

	.ClassInfoLeftLectureContainer .LectureBox h1 {
		font-size: 20px;
	}

	.CommentBoxContainer {
		padding: 0 15px;
	}

	/* ShareScreen */
	.SharePostScreen {
		width: 100%;
		height: 100vh;
		padding: 20px 10px 10px;
		gap: 20px;
		flex-direction: column;
		padding-bottom: 50px;

		.textContainer{
			width: 100%;
			max-height: 80vh;
		}
	}
}

@media screen and (max-height: 700px) {
	/* Notification */
	.responsive-notificationContainer .responsive-notification {
		margin-top: 30px;
	}

	.responsive-notificationContainer .responsive-notification .responsive-popup-header {
		width: 100%;
		height: 40px;
		margin-bottom: 5px;
	}

	.responsive-notification .responsive-popup-header p {
		font-size: 28px;
	}

	.responsive-notificationContainer .responsive-notification .responsive-popup-body {
		height: 407px;
	}

	.responsive-notification .responsive-popup-body .rp-notification {
		height: 80px;
	}

	/* Header Popup */
	.responsive-popup-box2 {
		width: auto;
		margin-top: 80px;
		/* grid-template-rows: repeat(3, 156px);
		grid-template-columns: repeat(3, 428px);
		grid-column-gap: 62px;
		grid-row-gap: 30px; */
	}

	.responsive-popup2 .close-popup {
		top: 20px;
		right: 20px;
	}

	/* create class Popup */
	.CreateClassMain {
		height: 100vh;
		overflow-y: scroll;
		padding-bottom: 150px;
	}
}

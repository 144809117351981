body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.Toastify {
  position: absolute;
  z-index: 999999;
}

.MobileDisplay {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(225deg, #010101, #0A1D3B, #063151, #051829, #010101);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.MobileDisplay h1 {
  padding: 50px;
  text-shadow: 0 0 6px rgba(202, 228, 225, 0.92), 0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52), 0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
}

.MobileDisplay img {
  width: 70%;
  border-radius: 50%;
}

.MobileDisplay h3 {
  text-align: center;
  padding: 50px;
  color: #fff;
}

.MobileDisplay button {
  padding: 20px 30px;
  color: #fff;
  background: green;
}





















:root {
  /* Fonts */
  --font-sans: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-serif: "Iowan Old Style", "Apple Garamond", Baskerville,
    "Times New Roman", "Droid Serif", Times, "Source Serif Pro", serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-mono: Consolas, monaco, "Ubuntu Mono", "Liberation Mono", "Courier New",
    Courier, monospace;

  --color-black: #000;
  --color-near-black: #0f1112;

  --color-dark-gray: #33393c;
  --color-mid-gray: #4b5358;
  --color-gray: #8d9ca7;

  --color-silver: #999;
  --color-light-silver: #a2b4c0;

  --color-moon-gray: #cfd0d1;
  --color-light-gray: #e7e8e9;
  --color-near-white: #f1f2f3;
  --color-white: #fff;

  --color-dark-red: #e7040f;
  --color-red: #fd2222;
  --color-light-red: #ff725c;

  --color-orange: #ff6300;
  --color-ivs-rocks-orange: #f91;
  --color-gold: #ffb700;
  --color-yellow: #ffd700;
  --color-light-yellow: #fbf1a9;

  --color-purple: #5e2ca5;
  --color-light-purple: #a463f2;

  --color-dark-pink: #d5008f;
  --color-hot-pink: #ff41b4;
  --color-pink: #ff80cc;
  --color-light-pink: #ffa3d7;

  --color-dark-green: #137752;
  --color-green: #0fd70b;
  --color-light-green: #9eebcf;

  --color-navy: #001b44;
  --color-dark-blue: #2026a2;
  --color-blue: #2b44ff;
  --color-light-blue: #6aa6ff;
  --color-lightest-blue: #e0eaff;

  --color-washed-blue: #f6fffe;
  --color-washed-green: #e8fdf5;
  --color-washed-yellow: #fffceb;
  --color-washed-red: #ffdfdf;

  --color-white-90: rgba(255, 255, 255, 0.9);
  --color-white-80: rgba(255, 255, 255, 0.8);
  --color-white-70: rgba(255, 255, 255, 0.7);
  --color-white-60: rgba(255, 255, 255, 0.6);
  --color-white-50: rgba(255, 255, 255, 0.5);
  --color-white-40: rgba(255, 255, 255, 0.4);
  --color-white-30: rgba(255, 255, 255, 0.3);
  --color-white-20: rgba(255, 255, 255, 0.2);
  --color-white-10: rgba(255, 255, 255, 0.1);
  --color-white-05: rgba(255, 255, 255, 0.05);

  --color-black-90: rgba(0, 0, 0, 0.9);
  --color-black-80: rgba(0, 0, 0, 0.8);
  --color-black-70: rgba(0, 0, 0, 0.7);
  --color-black-60: rgba(0, 0, 0, 0.6);
  --color-black-50: rgba(0, 0, 0, 0.5);
  --color-black-40: rgba(0, 0, 0, 0.4);
  --color-black-30: rgba(0, 0, 0, 0.3);
  --color-black-20: rgba(0, 0, 0, 0.2);
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-05: rgba(0, 0, 0, 0.05);

  /* Color tokens */
  --color--primary: var(--color-ivs-rocks-orange);
  --color--secondary: var(--color-gray);
  --color--positive: var(--color-green);
  --color--destructive: var(--color-red);
  --color--disabled: var(--color-moon-gray);
  --color--warn: var(--color-orange);

  /* Sizing */
  --section-max-width: 800px;
  --input-height: 42px;
  --radius: 10px;
  --radius-small: 4px;
  --header-height: 50px;
  --btn-floating-size: 56px;
  --btn-floating-icon-size: 40px;

  /* Light theme color assignment */
  --color-text-base: var(--color-black);
  --color-text-alt: var(--color-mid-gray);
  --color-text-inverted: var(--color-white);
  --color-text-hint: var(--color-gray);

  --color-text-primary: var(--color--primary);
  --color-text-secondary: var(--color--secondary);
  --color-text-tertiary: var(--color--tertiary);
  --color-text-positive: var(--color--positive);
  --color-text-destructive: var(--color--destructive);
  --color-text-warn: var(--color--warn);

  --color-bg-body: var(--color-white);
  --color-bg-base: var(--color-white);
  --color-bg-alt: var(--color-near-white);
  --color-bg-alt-2: var(--color-light-gray);
  --color-bg-inverted: var(--color-black);

  --color-bg-header: var(--color-bg-body);
  --color-bg-chat: var(--color-bg-body);
  --color-bg-chat-bubble: var(--color-bg-alt);
  --color-bg-player: var(--color-bg-alt);
  --color-bg-placeholder: var(--color-near-white);

  --color-bg-button: var(--color-near-white);
  --color-bg-button-active: var();
  --color-bg-button-focus: var();
  --color-bg-button-hover: var(--color-light-gray);

  --color-bg-button-inverted: var();
  --color-bg-button-inverted-active: var();
  --color-bg-button-inverted-focus: var();
  --color-bg-button-inverted-hover: var();

  --color-bg-button-primary-default: var(--color--primary);
  --color-bg-button-primary-active: var(--color--primary);
  --color-bg-button-primary-hover: var(--color-orange);

  --color-bg-button-secondary-default: var(--color-near-white);
  --color-bg-button-secondary-active: var();
  --color-bg-button-secondary-hover: var(--color-light-gray);

  --color-bg-button-floating: var(--color--primary);
  --color-bg-button-floating-active: var();
  --color-bg-button-floating-focus: var();
  --color-bg-button-floating-hover: var(--color--secondary);

  --color-bg-button-overlay: rgba(255, 255, 255, 0);
  --color-bg-button-overlay-hover: var(--color-white-10);

  --color-bg-input: var(--color-near-white);
  --color-bg-input-focus: var();

  --color-bg-notice-success: var(--color--positive);
  --color-bg-notice-error: var(--color--destructive);

  --color-border-base: var(--color-moon-gray);
  --color-border-error: var(--color--destructive);

  --grid-2-columns: 1fr 1fr;
  --grid-3-columns: 1fr 1fr 1fr;
  --grid-4-columns: 1fr 1fr 1fr 1fr;
  --grid-trio-columns: 1fr 3fr 1fr 1fr;

  --hide-on-mobile: inherit;
  --justify-center-responsive: center;
}

/* Themes */
@media (prefers-color-scheme: dark) {
  :root {
    --color--secondary: var(--color-light-blue);

    --color-text-base: var(--color-white);
    --color-text-alt: var(--color-mid-gray);
    --color-text-inverted: var(--color-black);
    --color-text-hint: var(--color-gray);

    --color-text-primary: var(--color--primary);
    --color-text-secondary: var(--color--secondary);
    --color-text-tertiary: var(--color--tertiary);
    --color-text-positive: var(--color--positive);
    --color-text-destructive: var(--color--destructive);
    --color-text-warn: var(--color-yellow);

    --color-bg-body: var(--color-near-black);
    --color-bg-base: var(--color-near-black);
    --color-bg-alt: var(--color-dark-gray);
    --color-bg-alt-2: var(--color-mid-gray);
    --color-bg-inverted: var(--color-white);

    --color-bg-header: var(--color-bg-body);
    --color-bg-chat: var(--color-bg-body);
    --color-bg-chat-bubble: var(--color-bg-alt);
    --color-bg-player: var(--color-black);
    --color-bg-placeholder: var(--color-near-black);

    --color-bg-button: var(--color-dark-gray);
    --color-bg-button-hover: var(--color-mid-gray);

    --color-bg-button-primary-default: var(--color--primary);

    --color-bg-button-secondary-default: var(--color-near-black);

    --color-bg-button-floating: var(--color--primary);
    --color-bg-button-floating-hover: var(--color--secondary);

    --color-bg-button-overlay: rgba(0, 0, 0, 0);
    --color-bg-button-overlay-hover: var(--color-black-10);

    --color-bg-input: var(--color-dark-gray);

    --color-bg-notice-success: var(--color--positive);
    --color-bg-notice-error: var(--color--destructive);

    --color-border-base: var(--color-dark-gray);
    --color-border-error: var(--color--destructive);

    --modal-action-gradient-from: rgba(15, 17, 18, 0.05);
    --modal-action-gradient-to: rgba(15, 17, 18, 1);

    --color--disabled: var(--color-dark-gray);
  }
}

/* Mobile */
@media only screen and (max-width: 520px) {
  :root {
    --hide-on-mobile: none;
    --justify-center-responsive: flex-start;
  }
}
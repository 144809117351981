.PopupBackground {
	width: 100%;
	height: 100vh;
	z-index: 11111;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(102, 102, 102, 0.1);
	backdrop-filter: blur(7.5px);
}

.EditClassPopup {
	width: 729px;
	background: #202020;
	padding: 65px 77px 84px 57px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 60px;

		h1 {
			font-weight: 500;
			font-size: 30px;
			line-height: 45px;
			color: #ffffff;
		}

		.icon {
			font-size: 35px;
			cursor: pointer;
		}
	}

	.coverContainer {
		margin-bottom: 60px;
		display: flex;
		align-items: center;
		justify-content: center;

		.ImageCont {
			width: 347px;
			height: 118px;
			border-radius: 5px;
			cursor: pointer;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			input {
				display: none;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 4;
			}

			p {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #ffffff;
				z-index: 5;
				margin: 0;

				svg {
					margin-right: 12px;
				}
			}
		}
	}

	.groupContainer {
		margin-bottom: 53px;

		label {
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: #666666;
			margin-bottom: 15px;
		}

		.groupBox {
			width: 100%;
			border: 1px solid #666666;
			border-radius: 10px;
			display: flex;
			align-items: center;
			overflow: hidden;
			padding-right: 27px;
			gap: 10px;

			input {
				flex: 1;
				height: 100%;
				height: 78px;
				font-weight: 500;
				font-size: 20.6506px;
				line-height: 31px;
				color: #ffffff;
				padding: 0 23px;
				background: transparent;
				border: none;
				outline: none;
			}

			textarea {
				height: 100%;
				height: 123px;
				flex: 1;
				resize: none;
				font-weight: 400;
				font-size: 12.0462px;
				line-height: 18px;
				color: rgb(255, 255, 255);
				padding: 21px;
				background: transparent;
				border: none;
				outline: none;
			}
		}
	}

	button {
		align-self: flex-end;
		width: 342px;
		max-height: 60px;
		min-height: 60px;
		background: #471cf1;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: #ffffff;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #21019f;
		}
	}
}

.CreateClassPopup {
	width: 729px;
	background: #202020;
	padding: 65px 77px 84px 57px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 60px;

		h1 {
			font-weight: 500;
			font-size: 30px;
			line-height: 45px;
			color: #ffffff;
		}

		.icon {
			font-size: 35px;
			cursor: pointer;
		}
	}

	.coverContainer {
		margin-bottom: 60px;
		display: flex;
		align-items: center;
		justify-content: center;

		.ImageCont {
			width: 347px;
			height: 118px;
			border-radius: 5px;
			cursor: pointer;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23666' stroke-width='1' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

			input {
				display: none;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 4;
			}

			p {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #ffffff;
				z-index: 5;
				margin: 0;

				svg {
					margin-right: 12px;
				}
			}
		}
	}

	.groupContainer {
		margin-bottom: 53px;

		label {
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: #666666;
			margin-bottom: 15px;
		}

		.groupBox {
			width: 100%;
			border: 1px solid #666666;
			border-radius: 10px;
			display: flex;
			align-items: center;
			overflow: hidden;

			input {
				flex: 1;
				height: 100%;
				height: 78px;
				font-weight: 500;
				font-size: 20.6506px;
				line-height: 31px;
				color: #ffffff;
				padding: 0 23px;
				background: transparent;
				border: none;
				outline: none;
			}

			textarea {
				height: 100%;
				height: 123px;
				flex: 1;
				resize: none;
				font-weight: 400;
				font-size: 12.0462px;
				line-height: 18px;
				color: rgb(255, 255, 255);
				padding: 21px;
				background: transparent;
				border: none;
				outline: none;
			}
		}
	}

	button {
		align-self: flex-end;
		width: 342px;
		max-height: 60px;
		min-height: 60px;
		background: #471cf1;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: #ffffff;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #21019f;
		}
	}
}

.AddLecture {
	width: 829px;
	background: #202020;
	padding: 47px 71px 42px 71px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	h1 {
		color: #fff;
		font-size: 28.815px;
		font-family: "Poppins";
		font-weight: 500;
		margin: 0;
	}
	p {
		color: #666;
		font-size: 17.289px;
		font-family: "Poppins";
		margin-bottom: 35px;
	}

	.InputContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 15px;
		margin-bottom: 15px;

		label {
			color: #666;
			font-size: 20px;
			font-family: "Poppins";
		}

		input {
			width: 100%;
			height: 78px;
			border-radius: 10px;
			border: 1px solid #666;
			background: transparent;
			padding: 20px;
			color: #fff;
			font-size: 25px;

			&::-webkit-calendar-picker-indicator {
				filter: invert(1);
			}
		}

		textarea {
			width: 100%;
			height: 123px;
			border-radius: 10px;
			border: 1px solid #666;
			background: transparent;
			padding: 20px;
			color: #fff;
			font-size: 20px;
			resize: none;
		}

		.timeContainer {
			height: 78px;
			display: flex;
			align-items: center;

			p {
				color: #fff;
				font-size: 17.627px;
				font-family: "Poppins";
				margin: 0;
				margin-right: 10px;
			}

			.time {
				width: 180.096px;
				height: 100%;
				margin-right: 39px;
				font-size: 20px;
			}
		}
	}

	button {
		margin-top: 25px;
		align-self: flex-end;
		width: 342px;
		max-height: 60px;
		min-height: 60px;
		background: #471cf1;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: #ffffff;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #21019f;
		}
	}
}

.JoinersPopup {
	width: 1069px;
	height: 777.736px;
	background: #202020;
	padding: 48px 220px 40px 189px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scrollbar-width: none;
	border-radius: 15.493px;
	background: #101010;
	position: relative;
	min-height: 200px;
	box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.1);
	user-select: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 28px 120px 20px 100px;
		height: 100vh;
	}

	.cross {
		color: #fff;
		font-size: 30px;
		position: absolute;
		top: 21px;
		right: 27px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
	}

	h1 {
		color: #fff;
		font-size: 43.962px;
		font-family: "Poppins";
		font-weight: 500;
		margin: 0;
	}
	h6 {
		color: #666;
		font-size: 21.981px;
		font-family: "Poppins";
		font-weight: 500;
		align-self: flex-end;
	}

	.CommentContainer {
		flex: 1;
		height: 100%;
		overflow-y: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		.Box {
			margin-bottom: 7px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.ProfileCont {
				display: flex;
				align-items: center;
				gap: 13px;

				.Img {
					width: 88px;
					height: 88px;
					border-radius: 50%;
					padding: 10px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}

				.textT {
					h4 {
						color: #fff;
						font-size: 31.259px;
						font-family: "Poppins";
						margin: 0;
					}
					p {
						color: rgba(255, 255, 255, 0.6);
						font-size: 17.051px;
						font-family: "Poppins";
						margin: 0;
					}
				}
			}

			button {
				width: 146px;
				height: 53px;
				background: transparent;
				color: #fff;
				font-size: 16px;
				font-family: "Poppins";
				border-radius: 9.159px;
				border: 1.465px solid #fff;
				transition: all 0.2s ease-in-out;

				&:hover {
					background-color: rgba(255, 0, 0, 0.8);
					letter-spacing: 1px;
				}

				&.Send {
					background: #471cf1;
					border: none;

					&:hover {
						letter-spacing: 0px;
						background-color: #1c008a;
					}
				}
			}
		}
	}
}

.ThreeDotPopup {
	width: 700px;
	background: #101010;
	display: flex;
	flex-direction: column;
	scrollbar-width: none;
	border-radius: 18.189px;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px 0 40px 0;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	.cross {
		color: #fff;
		font-size: 30px;
		position: absolute;
		top: 21px;
		right: 27px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
	}

	p {
		width: 100%;
		color: #fff;
		font-size: 29.103px;
		font-family: "Poppins";
		text-align: center;
		padding-bottom: 8px;
		border-bottom: 1px solid rgba(34, 34, 34, 1);
		cursor: pointer;
		padding-top: 20px;
	}
}

.SharePopup {
	width: 758px;
	background: #101010;
	display: flex;
	flex-direction: column;
	scrollbar-width: none;
	border-radius: 18.189px;
	overflow: hidden;
	position: relative;
	display: flex;
	padding: 41px 65px 77px 88px;
	user-select: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	.cross {
		color: #fff;
		font-size: 30px;
		position: absolute;
		top: 21px;
		right: 27px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
	}

	h1 {
		margin-bottom: 45px;
		color: #fff;
		font-size: 43.809px;
		font-family: "Poppins";
		font-weight: 500;
	}

	.SocialMediaIcon {
		display: flex;
		height: 75.453px;
		align-items: flex-start;
		gap: 52.596px;

		img {
			cursor: pointer;
		}
	}
}

.EndLivePopup {
	width: 580px;
	border-radius: 34.535px;
	background: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-height: 700px) {
		padding: 45px 77px 44px 57px;
		height: 100vh;
	}

	.cross {
		color: rgba(153, 153, 153, 1);
		font-size: 30px;
		position: absolute;
		top: 21px;
		right: 27px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
	}

	h1 {
		color: #000;
		font-size: 31.082px;
		font-family: "Poppins";
		font-weight: 500;
		margin-bottom: 20px;
	}

	.VideoContainer {
		width: 269.375px;
		height: 393.701px;
		border-radius: 25.901px;
		overflow: hidden;
		margin-bottom: 10px;

		img {
			width: 100%;
			height: 100%;
		}
		video {
			width: 100%;
			height: 100%;
		}
	}

	p {
		color: #666;
		font-size: 20.721px;
		font-family: "Poppins";
		margin-bottom: 20px;
	}

	h6 {
		color: #999;
		font-size: 24.175px;
		font-family: "Poppins";
		margin-bottom: 20px;
		cursor: pointer;
	}

	button {
		width: 203.758px;
		height: 63.89px;
		border-radius: 8.634px;
		background: #471cf1;
		color: #fff;
		font-size: 24.175px;
		font-family: Poppins;
		font-weight: 500;
		margin-bottom: 20px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background: #1e0096;
		}
	}

	h4 {
		color: #000;
		font-size: 24.175px;
		font-family: "Poppins";
		text-decoration-line: underline;
		align-self: flex-end;
		padding-right: 40px;
		cursor: pointer;
		margin-bottom: 30px;
	}
}
